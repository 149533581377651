import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { supabase } from '../lib/supabaseClient';
import { Star, Shield, Package, ArrowLeft, Heart, Share2, ShoppingBag } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CheckoutForm from '../components/CheckoutForm';
import { useCart } from '../lib/cartContext';

const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [details, setDetails] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
  const [addToCartSuccess, setAddToCartSuccess] = useState(false);
  const { dispatch } = useCart();

  useEffect(() => {
    if (id) {
      fetchProductData();
    }
  }, [id]);

  const fetchProductData = async () => {
    try {
      // Fetch product data
      const { data: productData, error: productError } = await supabase
        .from('products')
        .select('*')
        .eq('id', id)
        .single();

      if (productError) throw productError;

      // Fetch product details
      const { data: detailsData, error: detailsError } = await supabase
        .from('product_details')
        .select('*')
        .eq('product_id', id)
        .maybeSingle();

      if (detailsError) throw detailsError;

      // Fetch related products
      const { data: relatedData, error: relatedError } = await supabase
        .from('products')
        .select('*')
        .eq('category', productData.category)
        .neq('id', id)
        .limit(4);

      if (relatedError) throw relatedError;

      setProduct(productData);
      setDetails(detailsData || {
        specifications: {},
        features: [],
        care_instructions: []
      });
      setRelatedProducts(relatedData || []);
    } catch (error) {
      console.error('Error fetching product:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (value > 0) {
      setQuantity(value);
    }
  };

  const handleAddToCart = () => {
    if (!product) return;

    dispatch({
      type: 'ADD_ITEM',
      payload: {
        id: product.id,
        name: product.name,
        brand: product.brand,
        price: product.price,
        image: product.images[0],
        quantity
      }
    });

    setAddToCartSuccess(true);
    setTimeout(() => setAddToCartSuccess(false), 2000);
  };

  const handlePurchase = () => {
    setIsCheckoutOpen(true);
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-[#FFD700] border-t-transparent"></div>
      </div>
    );
  }

  if (!product) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-playfair mb-4">Product Not Found</h2>
          <Link to="/shop" className="text-[#FFD700] hover:underline">
            Return to Shop
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <Navbar />

      <div className="max-w-7xl mx-auto px-4 py-12">
        {/* Breadcrumb */}
        <div className="mb-8">
          <Link to="/shop" className="flex items-center text-gray-600 hover:text-[#FFD700]">
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Shop
          </Link>
        </div>

        {/* Product Overview */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Image Gallery */}
          <div className="space-y-4">
            <div className="aspect-square rounded-lg overflow-hidden bg-gray-100">
              <img
                src={product.images[selectedImage]}
                alt={product.name}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="grid grid-cols-4 gap-4">
              {product.images.map((image, index) => (
                <button
                  key={index}
                  onClick={() => setSelectedImage(index)}
                  className={`aspect-square rounded-lg overflow-hidden bg-gray-100 ${
                    selectedImage === index ? 'ring-2 ring-[#FFD700]' : ''
                  }`}
                >
                  <img
                    src={image}
                    alt={`${product.name} view ${index + 1}`}
                    className="w-full h-full object-cover"
                  />
                </button>
              ))}
            </div>
          </div>

          {/* Product Info */}
          <div>
            <div className="mb-6">
              <h1 className="font-playfair text-4xl mb-2">{product.name}</h1>
              <p className="text-2xl font-semibold mb-4">₹{product.price.toLocaleString()}</p>
              <div className="flex items-center space-x-4 mb-4">
                <div className="flex text-[#FFD700]">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="w-5 h-5 fill-current" />
                  ))}
                </div>
                <span className="text-gray-600">|</span>
                <span className="text-gray-600">Condition: {product.condition}</span>
              </div>
            </div>

            <div className="space-y-6 mb-8">
              <p className="text-gray-600">{product.description}</p>
              
              {details && Object.keys(details.specifications).length > 0 && (
                <>
                  <div className="border-t border-b border-gray-200 py-6">
                    <h3 className="font-playfair text-xl mb-4">Specifications</h3>
                    <dl className="grid grid-cols-1 gap-4">
                      {Object.entries(details.specifications).map(([key, value]) => (
                        <div key={key}>
                          <dt className="text-gray-600 capitalize">{key.replace(/_/g, ' ')}</dt>
                          <dd className="font-semibold">{value}</dd>
                        </div>
                      ))}
                    </dl>
                  </div>

                  {details.features.length > 0 && (
                    <div>
                      <h3 className="font-playfair text-xl mb-4">Features</h3>
                      <ul className="list-disc list-inside space-y-2 text-gray-600">
                        {details.features.map((feature, index) => (
                          <li key={index}>{feature}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="space-y-4">
              <div className="flex items-center space-x-4 mb-6">
                <label className="text-gray-700 font-medium">Quantity:</label>
                <input
                  type="number"
                  min="1"
                  value={quantity}
                  onChange={handleQuantityChange}
                  className="w-20 p-2 border rounded-md"
                />
              </div>

              <div className="flex flex-col space-y-4">
                <button
                  onClick={handleAddToCart}
                  className="flex items-center justify-center space-x-2 bg-[#FFD700] text-black py-4 rounded-md hover:bg-[#E5C100] transition-colors font-semibold relative overflow-hidden"
                >
                  <div className={`flex items-center justify-center space-x-2 transition-transform duration-300 ${
                    addToCartSuccess ? '-translate-y-full' : 'translate-y-0'
                  }`}>
                    <ShoppingBag className="w-5 h-5" />
                    <span>Add to Cart</span>
                  </div>
                  <div className={`absolute inset-0 flex items-center justify-center space-x-2 transition-transform duration-300 ${
                    addToCartSuccess ? 'translate-y-0' : 'translate-y-full'
                  }`}>
                    <Shield className="w-5 h-5" />
                    <span>Added to Cart!</span>
                  </div>
                </button>

                <button
                  onClick={handlePurchase}
                  className="w-full bg-black text-white py-4 rounded-md hover:bg-gray-800 transition-colors font-semibold"
                >
                  Purchase Now
                </button>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <button className="flex items-center justify-center space-x-2 border-2 border-[#FFD700] text-[#FFD700] py-3 rounded-md hover:bg-[#FFD700] hover:text-black transition-colors">
                  <Heart className="w-5 h -5 h-5" />
                  <span>Save</span>
                </button>
                <button className="flex items-center justify-center space-x-2 border-2 border-[#FFD700] text-[#FFD700] py-3 rounded-md hover:bg-[#FFD700] hover:text-black transition-colors">
                  <Share2 className="w-5 h-5" />
                  <span>Share</span>
                </button>
              </div>
            </div>

            {/* Trust Badges */}
            <div className="grid grid-cols-3 gap-4 mt-8">
              <div className="text-center">
                <Shield className="w-8 h-8 text-[#FFD700] mx-auto mb-2" />
                <p className="text-sm text-gray-600">100% Authentic</p>
              </div>
              <div className="text-center">
                <Package className="w-8 h-8 text-[#FFD700] mx-auto mb-2" />
                <p className="text-sm text-gray-600">Secure Shipping</p>
              </div>
              <div className="text-center">
                <Star className="w-8 h-8 text-[#FFD700] mx-auto mb-2" />
                <p className="text-sm text-gray-600">Quality Assured</p>
              </div>
            </div>
          </div>
        </div>

        {/* Care Instructions */}
        {details && details.care_instructions && details.care_instructions.length > 0 && (
          <div className="mt-16">
            <h2 className="font-playfair text-3xl mb-8">Care Instructions</h2>
            <div className="bg-gray-50 p-8 rounded-lg">
              <ul className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {details.care_instructions.map((instruction, index) => (
                  <li key={index} className="flex items-start space-x-3">
                    <Shield className="w-5 h-5 text-[#FFD700] flex-shrink-0 mt-1" />
                    <span>{instruction}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        {/* Related Products */}
        {relatedProducts.length > 0 && (
          <div className="mt-16">
            <h2 className="font-playfair text-3xl mb-8">Related Products</h2>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              {relatedProducts.map((related) => (
                <Link
                  key={related.id}
                  to={`/product/${related.id}`}
                  className="group"
                >
                  <div className="aspect-square rounded-lg overflow-hidden bg-gray-100 mb-4">
                    <img
                      src={related.images[0]}
                      alt={related.name}
                      className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                    />
                  </div>
                  <h3 className="font-playfair text-lg mb-2">{related.name}</h3>
                  <p className="text-[#FFD700] font-semibold">₹{related.price.toLocaleString()}</p>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>

      <CheckoutForm
        isOpen={isCheckoutOpen}
        onClose={() => setIsCheckoutOpen(false)}
        product={product}
        quantity={quantity}
      />

      <Footer />
    </div>
  );
};

export default ProductDetail;