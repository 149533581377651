import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShoppingBag, CreditCard, Truck, Shield, Ban as Bank, ArrowLeft } from 'lucide-react';
import { useCart } from '../lib/cartContext';
import { supabase } from '../lib/supabaseClient';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const bankDetails = {
  accountName: "WebInfoIT Private Limited",
  accountNumber: "123456786442",
  ifscCode: "43894839",
  bankName: "HDFC Bank"
};

const Checkout = () => {
  const navigate = useNavigate();
  const { state: cartState, dispatch } = useCart();
  const [paymentMethod, setPaymentMethod] = useState('');
  const [loading, setLoading] = useState(false);
  const [shippingAddress, setShippingAddress] = useState({
    fullName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    postalCode: '',
    phone: ''
  });

  const subtotal = cartState.total;
  const shipping = 0; // Free shipping
  const tax = subtotal * 0.18; // 18% GST
  const total = subtotal + shipping + tax;

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShippingAddress({
      ...shippingAddress,
      [e.target.name]: e.target.value
    });
  };

  const validateForm = () => {
    const requiredFields = ['fullName', 'addressLine1', 'city', 'state', 'postalCode', 'phone'];
    const missingFields = requiredFields.filter(field => !shippingAddress[field]);
    
    if (missingFields.length > 0) {
      alert('Please fill in all required fields');
      return false;
    }

    if (!paymentMethod) {
      alert('Please select a payment method');
      return false;
    }

    return true;
  };

  const handlePlaceOrder = async () => {
    if (!validateForm()) return;

    setLoading(true);
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        throw new Error('Please sign in to continue');
      }

      // First create shipping address
      const { data: addressData, error: addressError } = await supabase
        .from('shipping_addresses')
        .insert([{
          user_id: user.id,
          full_name: shippingAddress.fullName,
          address_line1: shippingAddress.addressLine1,
          address_line2: shippingAddress.addressLine2,
          city: shippingAddress.city,
          state: shippingAddress.state,
          postal_code: shippingAddress.postalCode,
          phone: shippingAddress.phone,
          country: 'India'
        }])
        .select()
        .single();

      if (addressError) throw addressError;

      // Prepare order items
      const orderItems = cartState.items.map(item => ({
        product_id: item.id,
        quantity: item.quantity
      }));

      // Create order using the function
      const { data: orderData, error: orderError } = await supabase
        .rpc('create_order', {
          p_user_id: user.id,
          p_shipping_address_id: addressData.id,
          p_payment_method: paymentMethod,
          p_items: orderItems
        });

      if (orderError) throw orderError;

      // Clear cart and redirect
      dispatch({ type: 'CLEAR_CART' });
      navigate('/checkout/success');
    } catch (error) {
      console.error('Error placing order:', error);
      alert('There was an error processing your order. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (cartState.items.length === 0) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Navbar />
        <div className="max-w-7xl mx-auto px-4 py-16">
          <div className="text-center">
            <ShoppingBag className="w-16 h-16 text-gray-400 mx-auto mb-4" />
            <h2 className="font-playfair text-2xl mb-4">Your cart is empty</h2>
            <p className="text-gray-600 mb-8">Add some items to your cart to proceed with checkout.</p>
            <button
              onClick={() => navigate('/shop')}
              className="bg-[#FFD700] text-black px-8 py-3 rounded-md font-semibold hover:bg-[#E5C100] transition-colors"
            >
              Continue Shopping
            </button>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      
      <div className="max-w-7xl mx-auto px-4 py-12">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-gray-600 hover:text-[#FFD700] mb-8"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back
        </button>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Order Summary */}
          <div>
            <h2 className="font-playfair text-3xl mb-8">Order Summary</h2>
            <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
              {cartState.items.map((item) => (
                <div key={item.id} className="flex items-start space-x-4 py-4 border-b last:border-0">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="w-20 h-20 object-cover rounded-md"
                  />
                  <div className="flex-1">
                    <h4 className="font-playfair text-lg">{item.brand}</h4>
                    <p className="text-gray-600">{item.name}</p>
                    <p className="text-sm text-gray-500">Quantity: {item.quantity}</p>
                  </div>
                  <div className="text-right">
                    <p className="font-medium">₹{(item.price * item.quantity).toLocaleString()}</p>
                  </div>
                </div>
              ))}

              <div className="mt-6 space-y-2">
                <div className="flex justify-between text-gray-600">
                  <span>Subtotal</span>
                  <span>₹{subtotal.toLocaleString()}</span>
                </div>
                <div className="flex justify-between text-gray-600">
                  <span>Shipping</span>
                  <span>Free</span>
                </div>
                <div className="flex justify-between text-gray-600">
                  <span>GST (18%)</span>
                  <span>₹{tax.toLocaleString()}</span>
                </div>
                <div className="flex justify-between font-semibold text-lg pt-4 border-t">
                  <span>Total</span>
                  <span>₹{total.toLocaleString()}</span>
                </div>
              </div>
            </div>

            {/* Trust Badges */}
            <div className="grid grid-cols-3 gap-4">
              <div className="text-center">
                <Shield className="w-8 h-8 text-[#FFD700] mx-auto mb-2" />
                <p className="text-sm text-gray-600">Secure Payment</p>
              </div>
              <div className="text-center">
                <Truck className="w-8 h-8 text-[#FFD700] mx-auto mb-2" />
                <p className="text-sm text-gray-600">Free Shipping</p>
              </div>
              <div className="text-center">
                <CreditCard className="w-8 h-8 text-[#FFD700] mx-auto mb-2" />
                <p className="text-sm text-gray-600">Easy Returns</p>
              </div>
            </div>
          </div>

          {/* Shipping & Payment Section */}
          <div>
            <h2 className="font-playfair text-3xl mb-8">Shipping Address</h2>
            <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="md:col-span-2">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Full Name *
                  </label>
                  <input
                    type="text"
                    name="fullName"
                    value={shippingAddress.fullName}
                    onChange={handleAddressChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-[#FFD700] focus:border-transparent"
                    placeholder="Enter your full name"
                    required
                  />
                </div>

                <div className="md:col-span-2">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Address Line 1 *
                  </label>
                  <input
                    type="text"
                    name="addressLine1"
                    value={shippingAddress.addressLine1}
                    onChange={handleAddressChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-[#FFD700] focus:border-transparent"
                    placeholder="Street address"
                    required
                  />
                </div>

                <div className="md:col-span-2">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Address Line 2
                  </label>
                  <input
                    type="text"
                    name="addressLine2"
                    value={shippingAddress.addressLine2}
                    onChange={handleAddressChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-[#FFD700] focus:border-transparent"
                    placeholder="Apartment, suite, etc. (optional)"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    City *
                  </label>
                  <input
                    type="text"
                    name="city"
                    value={shippingAddress.city}
                    onChange={handleAddressChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-[#FFD700] focus:border-transparent"
                    placeholder="Enter city"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    State *
                  </label>
                  <input
                    type="text"
                    name="state"
                    value={shippingAddress.state}
                    onChange={handleAddressChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-[#FFD700] focus:border-transparent"
                    placeholder="Enter state"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Postal Code *
                  </label>
                  <input
                    type="text"
                    name="postalCode"
                    value={shippingAddress.postalCode}
                    onChange={handleAddressChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-[#FFD700] focus:border-transparent"
                    placeholder="Enter postal code"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Phone *
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    value={shippingAddress.phone}
                    onChange={handleAddressChange}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-[#FFD700] focus:border-transparent"
                    placeholder="Enter phone number"
                    required
                  />
                </div>
              </div>
            </div>

            <h2 className="font-playfair text-3xl mb-8">Payment Method</h2>
            <div className="bg-white rounded-lg shadow-lg p-6">
              <div className="space-y-4">
                {/* Bank Transfer Option */}
                <label className="block border rounded-lg p-4 cursor-pointer hover:border-[#FFD700] transition-colors">
                  <div className="flex items-start">
                    <input
                      type="radio"
                      name="payment"
                      value="bank_transfer"
                      checked={paymentMethod === 'bank_transfer'}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      className="mt-1 mr-3"
                    />
                    <div className="flex-1">
                      <div className="flex items-center mb-2">
                        <Bank className="w-5 h-5 text-[#FFD700] mr-2" />
                        <span className="font-semibold">Bank Transfer</span>
                      </div>
                      {paymentMethod === 'bank_transfer' && (
                        <div className="mt-2 p-3 bg-gray-50 rounded-md text-sm">
                          <p className="font-semibold mb-1">Bank Details:</p>
                          <p>Account Name: {bankDetails.accountName}</p>
                          <p>Account Number: {bankDetails.accountNumber}</p>
                          <p>IFSC Code: {bankDetails.ifscCode}</p>
                          <p>Bank Name: {bankDetails.bankName}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </label>

                {/* Cash on Delivery Option */}
                <label className="block border rounded-lg p-4 cursor-pointer hover:border-[#FFD700] transition-colors">
                  <div className="flex items-start">
                    <input
                      type="radio"
                      name="payment"
                      value="cod"
                      checked={paymentMethod === 'cod'}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      className="mt-1 mr-3"
                    />
                    <div className="flex items-center">
                      <CreditCard className="w-5 h-5 text-[#FFD700] mr-2" />
                      <span className="font-semibold">Cash on Delivery</span>
                    </div>
                  </div>
                </label>
              </div>

              <button
                onClick={handlePlaceOrder}
                disabled={loading}
                className="w-full mt-8 bg-[#FFD700] text-black py-4 rounded-md font-semibold hover:bg-[#E5C100] transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? 'Processing...' : 'Place Order'}
              </button>

              <p className="mt-4 text-sm text-gray-500 text-center">
                By placing your order, you agree to our Terms of Service and Privacy Policy
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Checkout;