import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Shield, Lock, FileText } from 'lucide-react';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      
      {/* Hero Section */}
      <section className="relative h-[40vh] flex items-center justify-center">
        <div 
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: 'url("https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="absolute inset-0 bg-black/50"></div>
        </div>
        <div className="relative z-10 text-center text-white px-4">
          <h1 className="font-playfair text-5xl md:text-6xl mb-4">Privacy Policy</h1>
          <p className="font-lato text-xl max-w-2xl mx-auto">
            How we protect and handle your personal information
          </p>
        </div>
      </section>

      {/* Content Section */}
      <section className="py-16 px-4">
        <div className="max-w-4xl mx-auto">
          <div className="prose prose-lg">
            <div className="mb-8">
              <p className="text-sm text-gray-500">Effective Date: February 18, 2025</p>
            </div>

            <div className="mb-8">
              <h2 className="font-playfair text-3xl mb-4">1. Introduction</h2>
              <p>
                Welcome to WebInfoIT - Revolutionizing Trust in Pre-Owned Luxury Goods. Your privacy is important to us, and we are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and share your information when you use our website and services.
              </p>
            </div>

            <div className="mb-8">
              <h2 className="font-playfair text-3xl mb-4">2. Information We Collect</h2>
              <p>We collect different types of information, including:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li><strong>Personal Information:</strong> Name, email address, phone number, billing and shipping addresses.</li>
                <li><strong>Account Credentials:</strong> Username and password for account creation and security purposes.</li>
                <li><strong>Payment Information:</strong> Credit/debit card details or payment transaction details when making purchases.</li>
                <li><strong>Transaction History:</strong> Records of purchases and interactions with our website.</li>
                <li><strong>Communication Preferences:</strong> Your preferences for receiving updates, promotions, and notifications.</li>
              </ul>
            </div>

            <div className="mb-8">
              <h2 className="font-playfair text-3xl mb-4">3. How We Use Your Information</h2>
              <p>We use the collected information for the following purposes:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li><strong>Processing Transactions:</strong> To facilitate your orders, payments, and deliveries.</li>
                <li><strong>Product Authenticity Verification:</strong> Ensuring the quality and genuineness of our products.</li>
                <li><strong>Customer Support:</strong> Assisting with inquiries, returns, and troubleshooting.</li>
                <li><strong>Service Improvements:</strong> Enhancing user experience, website performance, and product offerings.</li>
                <li><strong>Important Updates:</strong> Sending notifications about account activity, promotions, or changes in policies.</li>
              </ul>
            </div>

            <div className="mb-8">
              <h2 className="font-playfair text-3xl mb-4">4. Information Sharing and Disclosure</h2>
              <p>We do not sell or rent your personal information. However, we may share information in the following cases:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li><strong>With Your Consent:</strong> When you authorize us to share your information with third parties.</li>
                <li><strong>Transaction Processing:</strong> Sharing necessary details with payment gateways, logistics providers, or partners to complete purchases and deliveries.</li>
                <li><strong>Legal Compliance:</strong> Disclosing information as required by law or in response to legal requests.</li>
                <li><strong>Fraud Prevention:</strong> Protecting our rights, investigating fraud, and ensuring secure transactions.</li>
              </ul>
            </div>

            <div className="mb-8">
              <h2 className="font-playfair text-3xl mb-4">5. Data Security</h2>
              <p>
                We implement appropriate security measures to protect your personal data from unauthorized access, misuse, or disclosure. However, no online platform can guarantee complete security. We encourage you to use strong passwords and safeguard your login details.
              </p>
            </div>

            <div className="mb-8">
              <h2 className="font-playfair text-3xl mb-4">6. Your Rights and Choices</h2>
              <p>You have the right to:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Access, update, or correct your personal information</li>
                <li>Opt-out of marketing emails and notifications</li>
                <li>Request the deletion of your personal data, subject to legal obligations</li>
                <li>Restrict or object to certain data processing activities</li>
              </ul>
            </div>

            <div className="mb-8">
              <h2 className="font-playfair text-3xl mb-4">7. Retention of Data</h2>
              <p>
                We retain your personal information as long as necessary to fulfill the purposes outlined in this policy or as required by law.
              </p>
            </div>

            <div className="mb-8">
              <h2 className="font-playfair text-3xl mb-4">8. Third-Party Links</h2>
              <p>
                Our website may contain links to third-party sites. We are not responsible for the privacy practices or content of external websites.
              </p>
            </div>

            <div className="mb-8">
              <h2 className="font-playfair text-3xl mb-4">9. Changes to This Privacy Policy</h2>
              <p>
                We may update this policy periodically. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy regularly.
              </p>
            </div>

            <div className="mb-8">
              <h2 className="font-playfair text-3xl mb-4">10. Contact Us</h2>
              <p>For any questions regarding this Privacy Policy, please contact us at:</p>
              <p className="font-semibold mt-2">privacy@insoftek.com</p>
            </div>

            <div className="mt-8 p-6 bg-gray-50 rounded-lg">
              <p className="text-gray-600 italic">
                By using our website, you acknowledge that you have read and understood this Privacy Policy.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;