import React from 'react';
import { Link } from 'react-router-dom';
import { CheckCircle, Package, Clock, ArrowRight } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const CheckoutSuccess = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      
      <div className="max-w-2xl mx-auto px-4 py-16">
        <div className="bg-white rounded-lg shadow-lg p-8 text-center">
          <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-6">
            <CheckCircle className="w-8 h-8 text-green-500" />
          </div>

          <h1 className="font-playfair text-3xl mb-4">Order Placed Successfully!</h1>
          <p className="text-gray-600 mb-8">
            Thank you for your order. We'll send you a confirmation email shortly with your order details.
          </p>

          <div className="space-y-6 mb-8">
            <div className="flex items-center space-x-4">
              <div className="w-10 h-10 bg-[#FFD700]/10 rounded-full flex items-center justify-center">
                <Package className="w-5 h-5 text-[#FFD700]" />
              </div>
              <div className="flex-1 text-left">
                <h3 className="font-medium">Order Processing</h3>
                <p className="text-sm text-gray-500">We're preparing your order</p>
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <div className="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center">
                <Clock className="w-5 h-5 text-gray-400" />
              </div>
              <div className="flex-1 text-left">
                <h3 className="font-medium">Estimated Delivery</h3>
                <p className="text-sm text-gray-500">3-5 business days</p>
              </div>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <Link
              to="/shop"
              className="inline-flex items-center justify-center space-x-2 bg-[#FFD700] text-black px-6 py-3 rounded-md font-semibold hover:bg-[#E5C100] transition-colors"
            >
              <span>Continue Shopping</span>
              <ArrowRight className="w-4 h-4" />
            </Link>
            <Link
              to="/profile"
              className="inline-flex items-center justify-center space-x-2 border-2 border-[#FFD700] text-[#FFD700] px-6 py-3 rounded-md font-semibold hover:bg-[#FFD700] hover:text-black transition-colors"
            >
              <span>View Orders</span>
              <ArrowRight className="w-4 h-4" />
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CheckoutSuccess;