import React from 'react';
import { Facebook, Twitter, Instagram, Mail } from 'lucide-react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-luxury-900 text-white">
      <div className="max-w-7xl mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
          {/* Company Info */}
          <div className="space-y-6">
            <h3 className="font-playfair text-2xl">WebInfoIT</h3>
            <p className="font-lato text-gray-400">
              WEBINFO IT CONSULTING PRIVATE LIMITED<br />
              OFFICE NO-1133 11TH FLOOR<br />
              TOWER B2 SPAZE I TECH PARK<br />
              SECTOR 49 GURGAON<br />
              Phone: +91-9818935567
            </p>
            <div className="flex space-x-4">
              <SocialIcon Icon={Facebook} href="#" />
              <SocialIcon Icon={Twitter} href="#" />
              <SocialIcon Icon={Instagram} href="#" />
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h4 className="font-playfair text-lg mb-6">Quick Links</h4>
            <ul className="space-y-4">
              <FooterLink to="/about" text="About Us" />
              <FooterLink to="/how-it-works" text="How It Works" />
              <FooterLink to="/shop" text="Our Services" />
              <FooterLink to="/sell" text="Sell With Us" />
              <FooterLink to="/blog" text="Blog" />
            </ul>
          </div>

          {/* Support */}
          <div>
            <h4 className="font-playfair text-lg mb-6">Support</h4>
            <ul className="space-y-4">
              <FooterLink to="/faq" text="FAQ" />
              <FooterLink to="/contact" text="Contact Us" />
              <FooterLink to="/shipping" text="Shipping Policy" />
              <FooterLink to="/returns" text="Return Policy" />
              <FooterLink to="/refunds" text="Refund Policy" />
            </ul>
          </div>

          {/* Legal */}
          <div>
            <h4 className="font-playfair text-lg mb-6">Legal</h4>
            <ul className="space-y-4">
              <FooterLink to="/privacy" text="Privacy Policy" />
              <FooterLink to="/terms" text="Terms of Service" />
            </ul>
            <div className="mt-8">
              <h4 className="font-playfair text-lg mb-6">Newsletter</h4>
              <form className="flex">
                <input
                  type="email"
                  placeholder="Your email"
                  className="flex-1 px-4 py-3 bg-luxury-800 border border-luxury-700 rounded-l-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-gold-300"
                />
                <button
                  type="submit"
                  className="bg-gold-300 text-luxury-900 px-6 py-3 rounded-r-md hover:bg-gold-400 transition-colors flex items-center justify-center"
                >
                  <Mail className="w-5 h-5" />
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="border-t border-luxury-800 mt-16 pt-8 text-center">
          <p className="font-lato text-gray-400">
            © {new Date().getFullYear()} WebInfoIT. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

const FooterLink = ({ to, text }) => (
  <li>
    <Link
      to={to}
      className="font-lato text-gray-400 hover:text-gold-300 transition-colors inline-block"
    >
      {text}
    </Link>
  </li>
);

const SocialIcon = ({ Icon, href }) => (
  <a
    href={href}
    className="p-3 rounded-full border border-luxury-700 hover:border-gold-300 hover:text-gold-300 transition-all duration-300 hover:scale-110"
  >
    <Icon className="w-5 h-5" />
  </a>
);

export default Footer;