import React, { useState } from 'react';
import { Search, CreditCard, Package, CheckCircle, Upload, Truck, DollarSign, Mail, FileCheck, Shield, Clock, ArrowRight } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import TransactionForm from '../components/TransactionForm';

const HowItWorks = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      
      {/* Hero Section */}
      <section className="relative h-[60vh] flex items-center justify-center">
        <div 
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: 'url("https://images.unsplash.com/photo-1441986300917-64674bd600d8?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="absolute inset-0 bg-black/50"></div>
        </div>
        <div className="relative z-10 text-center text-white px-4 max-w-5xl mx-auto">
          <h1 className="font-playfair text-5xl md:text-6xl mb-6">The WebInfoIT Process</h1>
          <p className="font-lato text-xl md:text-2xl max-w-3xl mx-auto mb-8">
            A secure and transparent journey for luxury goods transactions
          </p>
          <button
            onClick={() => setIsModalOpen(true)}
            className="bg-[#FFD700] text-black px-8 py-4 rounded-md font-semibold hover:bg-[#E5C100] transition-colors text-lg inline-flex items-center"
          >
            Start Transaction
            <ArrowRight className="ml-2 w-5 h-5" />
          </button>
        </div>
      </section>

      {/* Process Flowchart Section */}
      <section className="py-20 px-4 bg-gray-50">
        <div className="max-w-7xl mx-auto">
          <h2 className="font-playfair text-4xl text-center mb-16">Transaction Flow</h2>
          <div className="relative">
            {/* Flowchart Grid */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {/* Column 1 - Steps 1-3 */}
              <div className="space-y-8">
                <FlowchartStep
                  number="1"
                  icon={FileCheck}
                  title="Buyer Request"
                  description="Form submission & fee payment"
                  isFirst={true}
                />
                <FlowchartStep
                  number="2"
                  icon={Mail}
                  title="Seller Confirmation"
                  description="Details verification with seller"
                />
                <FlowchartStep
                  number="3"
                  icon={CreditCard}
                  title="Payment Instructions"
                  description="Bank details & order tracking"
                />
              </div>

              {/* Column 2 - Steps 4-6 */}
              <div className="space-y-8">
                <FlowchartStep
                  number="4"
                  icon={DollarSign}
                  title="Payment Confirmation"
                  description="Payment verification & pickup initiation"
                />
                <FlowchartStep
                  number="5"
                  icon={Truck}
                  title="Pickup Process"
                  description="Videography & secure sealing"
                />
                <FlowchartStep
                  number="6"
                  icon={Package}
                  title="Facility Receipt"
                  description="Package arrival & inspection start"
                />
              </div>

              {/* Column 3 - Steps 7-9 */}
              <div className="space-y-8">
                <FlowchartStep
                  number="7"
                  icon={Search}
                  title="Inspection Report"
                  description="Authentication results shared"
                />
                <FlowchartStep
                  number="8"
                  icon={Truck}
                  title="Post-Inspection Action"
                  description="Delivery or return based on results"
                />
                <FlowchartStep
                  number="9"
                  icon={DollarSign}
                  title="Payment Release"
                  description="Final settlement based on outcome"
                  isLast={true}
                />
              </div>
            </div>

            {/* Connecting Lines - Only visible on desktop */}
            <div className="hidden md:block absolute top-0 left-0 w-full h-full pointer-events-none">
              {/* Horizontal Lines */}
              <div className="absolute top-[15%] left-[33%] w-[34%] h-0.5 bg-[#FFD700]/30">
                {/* Right Arrow */}
                <div className="absolute right-0 top-1/2 -translate-y-1/2 w-3 h-3 border-t-2 border-r-2 border-[#FFD700] rotate-45 translate-x-1"></div>
              </div>
              <div className="absolute top-[48%] left-[33%] w-[34%] h-0.5 bg-[#FFD700]/30">
                {/* Right Arrow */}
                <div className="absolute right-0 top-1/2 -translate-y-1/2 w-3 h-3 border-t-2 border-r-2 border-[#FFD700] rotate-45 translate-x-1"></div>
              </div>
              <div className="absolute top-[81%] left-[33%] w-[34%] h-0.5 bg-[#FFD700]/30">
                {/* Right Arrow */}
                <div className="absolute right-0 top-1/2 -translate-y-1/2 w-3 h-3 border-t-2 border-r-2 border-[#FFD700] rotate-45 translate-x-1"></div>
              </div>
              
              {/* Vertical Lines with Arrows */}
              <div className="absolute top-[15%] left-[33%] w-0.5 h-[66%] bg-[#FFD700]/30">
                {/* Down Arrow */}
                <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-3 h-3 border-b-2 border-r-2 border-[#FFD700] rotate-45 translate-y-1"></div>
              </div>
              <div className="absolute top-[15%] right-[33%] w-0.5 h-[66%] bg-[#FFD700]/30">
                {/* Down Arrow */}
                <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-3 h-3 border-b-2 border-r-2 border-[#FFD700] rotate-45 translate-y-1"></div>
              </div>

              {/* Diagonal Connectors */}
              <div className="absolute top-[32%] left-[33%] w-[34%] h-[32%]">
                <div className="absolute w-full h-full border-b-2 border-[#FFD700]/30 skew-y-30">
                  {/* Diagonal Arrow */}
                  <div className="absolute bottom-0 right-0 w-3 h-3 border-b-2 border-r-2 border-[#FFD700] rotate-45 translate-x-1 translate-y-1"></div>
                </div>
              </div>
              <div className="absolute top-[32%] right-[33%] w-[34%] h-[32%]">
                <div className="absolute w-full h-full border-b-2 border-[#FFD700]/30 -skew-y-30">
                  {/* Diagonal Arrow */}
                  <div className="absolute bottom-0 left-0 w-3 h-3 border-b-2 border-r-2 border-[#FFD700] rotate-45 -translate-x-1 translate-y-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Detailed Journey Cards */}
      <section className="py-20 px-4">
        <div className="max-w-7xl mx-auto">
          <h2 className="font-playfair text-4xl text-center mb-16">Detailed Process Overview</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Step 1-3 */}
            <div className="space-y-8">
              <JourneyStep
                number="1"
                icon={FileCheck}
                title="Buyer Request"
                description="Fill out the form, pay fees, and provide seller details, addresses, and order specifications."
                image="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
              />
              <JourneyStep
                number="2"
                icon={Mail}
                title="Seller Confirmation"
                description="We contact the seller on your behalf to confirm all provided details."
                image="https://images.unsplash.com/photo-1579389083046-e3df9c2b3325?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
              />
              <JourneyStep
                number="3"
                icon={CreditCard}
                title="Payment Instructions"
                description="Receive bank details and unique order number for payment tracking."
                image="https://images.unsplash.com/photo-1559067096-49ebca3406aa?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
              />
            </div>

            {/* Step 4-6 */}
            <div className="space-y-8">
              <JourneyStep
                number="4"
                icon={DollarSign}
                title="Payment Confirmation"
                description="Once payment is received, we confirm with the seller and initiate pickup with a unique seal number."
                image="https://images.unsplash.com/photo-1518458028785-8fbcd101ebb9?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
              />
              <JourneyStep
                number="5"
                icon={Truck}
                title="Pickup Process"
                description="Our team records the process, packs and seals the product. Seal number verification is done."
                image="https://images.unsplash.com/photo-1566576912321-d58ddd7a6088?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
              />
              <JourneyStep
                number="6"
                icon={Package}
                title="Facility Receipt"
                description="Package arrives at our facility, notifications sent, and inspection begins."
                image="https://images.unsplash.com/photo-1580674285054-bed31e145f59?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
              />
            </div>

            {/* Step 7-9 */}
            <div className="space-y-8">
              <JourneyStep
                number="7"
                icon={Search}
                title="Inspection Report"
                description="Detailed inspection report shared with both buyer and seller."
                image="https://images.unsplash.com/photo-1587825140708-dfaf72ae4b04?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
              />
              <JourneyStep
                number="8"
                icon={Truck}
                title="Post-Inspection Action"
                description="Product is delivered to buyer if passed, or returned to seller if failed."
                image="https://images.unsplash.com/photo-1526367790999-0150786686a2?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
              />
              <JourneyStep
                number="9"
                icon={DollarSign}
                title="Payment Release"
                description="Payment released to appropriate party based on inspection outcome."
                image="https://images.unsplash.com/photo-1579621970563-ebec7560ff3e?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Key Features Section */}
      <section className="py-20 px-4 bg-luxury-900">
        <div className="max-w-7xl mx-auto">
          <h2 className="font-playfair text-4xl text-center text-white mb-16">Our Guarantees</h2>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <FeatureCard
              Icon={Shield}
              title="100% Authenticity"
              description="Every item undergoes rigorous authentication by certified experts"
            />
            <FeatureCard
              Icon={Clock}
              title="Real-time Updates"
              description="Track your transaction status with email notifications at every step"
            />
            <FeatureCard
              Icon={CreditCard}
              title="Secure Payments"
              description="Protected transactions with our secure escrow system"
            />
            <FeatureCard
              Icon={Mail}
              title="Full Transparency"
              description="Complete documentation and communication throughout the process"
            />
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 px-4 bg-gray-50">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="font-playfair text-4xl mb-8">Ready to Get Started?</h2>
          <div className="flex flex-col sm:flex-row gap-6 justify-center">
            <button className="bg-[#FFD700] text-black px-8 py-4 rounded-md font-semibold hover:bg-[#E5C100] transition-colors text-lg">
              Start Shopping
            </button>
            <button className="border-2 border-[#FFD700] text-black px-8 py-4 rounded-md font-semibold hover:bg-[#FFD700] transition-colors text-lg">
              Start Selling
            </button>
          </div>
        </div>
      </section>

      <TransactionForm
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />

      <Footer />
    </div>
  );
};

const FlowchartStep = ({ number, icon: Icon, title, description, isFirst = false, isLast = false }) => {
  return (
    <div className="relative">
      <div className="bg-white rounded-lg p-6 shadow-lg hover:shadow-xl transition-shadow">
        <div className="flex items-start space-x-4">
          <div className="flex-shrink-0">
            <div className="relative">
              <div className="w-12 h-12 bg-[#FFD700]/10 rounded-full flex items-center justify-center">
                <Icon className="w-6 h-6 text-[#FFD700]" />
              </div>
              <div className="absolute -top-2 -right-2 w-6 h-6 bg-[#FFD700] rounded-full flex items-center justify-center">
                <span className="text-black font-bold text-sm">{number}</span>
              </div>
            </div>
          </div>
          <div>
            <h3 className="font-playfair text-lg mb-1">{title}</h3>
            <p className="text-sm text-gray-600">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const JourneyStep = ({ number, icon: Icon, title, description, image }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow">
      <div className="relative h-48">
        <img
          src={image}
          alt={title}
          className="w-full h-full object-cover"
        />
        <div className="absolute top-4 right-4 w-8 h-8 bg-[#FFD700] rounded-full flex items-center justify-center">
          <span className="text-black font-bold">{number}</span>
        </div>
      </div>
      <div className="p-6">
        <div className="flex items-center space-x-3 mb-4">
          <div className="p-2 bg-[#FFD700]/10 rounded-full">
            <Icon className="w-6 h-6 text-[#FFD700]" />
          </div>
          <h3 className="font-playfair text-xl">{title}</h3>
        </div>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  );
};

const FeatureCard = ({ Icon, title, description }) => {
  return (
    <div className="bg-luxury-800 rounded-lg p-6 hover:bg-luxury-700 transition-colors">
      <div className="flex flex-col items-center text-center">
        <div className="p-3 bg-[#FFD700]/10 rounded-full mb-4">
          <Icon className="w-8 h-8 text-[#FFD700]" />
        </div>
        <h3 className="font-playfair text-xl text-white mb-3">{title}</h3>
        <p className="text-gray-400">{description}</p>
      </div>
    </div>
  );
};

export default HowItWorks;