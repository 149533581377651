import React from 'react';
import { CheckCircle, Package, Truck, Clock, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

interface OrderConfirmationProps {
  orderNumber: string;
  total: number;
  paymentMethod: string;
  shippingAddress: any;
}

const OrderConfirmation: React.FC<OrderConfirmationProps> = ({
  orderNumber,
  total,
  paymentMethod,
  shippingAddress
}) => {
  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity bg-black bg-opacity-75" />

        <div className="inline-block w-full max-w-2xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-lg shadow-xl">
          <div className="p-8">
            {/* Success Icon */}
            <div className="flex justify-center mb-8">
              <div className="w-20 h-20 bg-green-100 rounded-full flex items-center justify-center">
                <CheckCircle className="w-12 h-12 text-green-500" />
              </div>
            </div>

            {/* Order Success Message */}
            <div className="text-center mb-8">
              <h2 className="font-playfair text-3xl mb-4">Order Confirmed!</h2>
              <p className="text-gray-600">
                Thank you for your order. We'll send you a confirmation email shortly.
              </p>
            </div>

            {/* Order Details */}
            <div className="bg-gray-50 rounded-lg p-6 mb-8">
              <div className="flex justify-between items-center mb-4">
                <span className="text-gray-600">Order Number</span>
                <span className="font-semibold">{orderNumber}</span>
              </div>
              <div className="flex justify-between items-center mb-4">
                <span className="text-gray-600">Total Amount</span>
                <span className="font-semibold">₹{total.toLocaleString()}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-gray-600">Payment Method</span>
                <span className="font-semibold capitalize">{paymentMethod.replace('_', ' ')}</span>
              </div>
            </div>

            {/* Shipping Address */}
            <div className="mb-8">
              <h3 className="font-playfair text-xl mb-4">Shipping Address</h3>
              <div className="bg-gray-50 rounded-lg p-6">
                <p className="font-semibold mb-2">{shippingAddress.full_name}</p>
                <p className="text-gray-600">
                  {shippingAddress.address_line1}
                  {shippingAddress.address_line2 && <>, {shippingAddress.address_line2}</>}
                </p>
                <p className="text-gray-600">
                  {shippingAddress.city}, {shippingAddress.state} {shippingAddress.postal_code}
                </p>
                <p className="text-gray-600">{shippingAddress.phone}</p>
              </div>
            </div>

            {/* Order Timeline */}
            <div className="mb-8">
              <h3 className="font-playfair text-xl mb-4">What's Next?</h3>
              <div className="space-y-4">
                <TimelineStep
                  icon={Package}
                  title="Order Processing"
                  description="We're preparing your order for shipment"
                  isActive
                />
                <TimelineStep
                  icon={Truck}
                  title="Order Shipment"
                  description="Your order will be picked up by our secure courier"
                />
                <TimelineStep
                  icon={Clock}
                  title="Delivery"
                  description="Estimated delivery within 3-5 business days"
                />
              </div>
            </div>

            {/* Actions */}
            <div className="flex flex-col sm:flex-row gap-4">
              <Link
                to="/shop"
                className="flex-1 bg-[#FFD700] text-black px-6 py-3 rounded-md font-semibold hover:bg-[#E5C100] transition-colors text-center"
              >
                Continue Shopping
              </Link>
              <Link
                to="/contact"
                className="flex-1 border-2 border-[#FFD700] text-[#FFD700] px-6 py-3 rounded-md font-semibold hover:bg-[#FFD700] hover:text-black transition-colors text-center"
              >
                Need Help?
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TimelineStep = ({ icon: Icon, title, description, isActive = false }) => {
  return (
    <div className="flex items-start space-x-4">
      <div className={`p-2 rounded-full ${isActive ? 'bg-[#FFD700]/20 text-[#FFD700]' : 'bg-gray-100 text-gray-400'}`}>
        <Icon className="w-6 h-6" />
      </div>
      <div>
        <h4 className={`font-semibold ${isActive ? 'text-[#FFD700]' : 'text-gray-400'}`}>{title}</h4>
        <p className={isActive ? 'text-gray-600' : 'text-gray-400'}>{description}</p>
      </div>
    </div>
  );
};

export default OrderConfirmation;