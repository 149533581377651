import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft, Calendar, Clock, Share2 } from 'lucide-react';
import { supabase } from '../lib/supabaseClient';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

type BlogPost = {
  id: string;
  title: string;
  excerpt: string;
  content: string;
  category: string;
  image_url: string;
  author: string;
  reading_time: number;
  published_at: string;
};

const BlogPost = () => {
  const { id } = useParams();
  const [post, setPost] = useState<BlogPost | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (id) {
      fetchPost();
    }
  }, [id]);

  const fetchPost = async () => {
    try {
      setLoading(true);
      setError(null);

      const { data, error } = await supabase
        .from('blog_posts')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;
      if (!data) throw new Error('Post not found');

      setPost(data);
    } catch (err) {
      console.error('Error fetching blog post:', err);
      setError(err instanceof Error ? err.message : 'Failed to load blog post');
    } finally {
      setLoading(false);
    }
  };

  const handleShare = async () => {
    try {
      if (navigator.share && post) {
        await navigator.share({
          title: post.title,
          text: post.excerpt,
          url: window.location.href,
        });
      } else {
        await navigator.clipboard.writeText(window.location.href);
        alert('Link copied to clipboard!');
      }
    } catch (err) {
      console.error('Error sharing:', err);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-[#FFD700] border-t-transparent"></div>
      </div>
    );
  }

  if (error || !post) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-playfair mb-4">
            {error || 'Post Not Found'}
          </h2>
          <Link to="/blog" className="text-[#FFD700] hover:underline">
            Return to Blog
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      
      {/* Hero Section */}
      <section className="relative h-[60vh] flex items-center justify-center">
        <div 
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: `url("${post.image_url}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="absolute inset-0 bg-black/50"></div>
        </div>
        <div className="relative z-10 text-center text-white px-4 max-w-4xl mx-auto">
          <span className="inline-block bg-[#FFD700]/10 text-[#FFD700] px-4 py-2 rounded-full text-sm font-semibold mb-6">
            {post.category}
          </span>
          <h1 className="font-playfair text-4xl md:text-5xl mb-6">{post.title}</h1>
          <div className="flex items-center justify-center space-x-6 text-sm">
            <div className="flex items-center">
              <Calendar className="w-4 h-4 mr-2" />
              {new Date(post.published_at).toLocaleDateString()}
            </div>
            <div className="flex items-center">
              <Clock className="w-4 h-4 mr-2" />
              {post.reading_time} min read
            </div>
          </div>
        </div>
      </section>

      {/* Content Section */}
      <section className="py-16 px-4">
        <div className="max-w-3xl mx-auto">
          {/* Back Link */}
          <Link to="/blog" className="inline-flex items-center text-gray-600 hover:text-[#FFD700] mb-8">
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to Blog
          </Link>

          {/* Author Info */}
          <div className="mb-8 pb-8 border-b">
            <p className="font-semibold text-lg">{post.author}</p>
            <p className="text-gray-600">Luxury Market Analyst</p>
          </div>

          {/* Post Content */}
          <div className="prose prose-lg max-w-none">
            <p className="text-xl text-gray-600 mb-8 font-lato leading-relaxed">
              {post.excerpt}
            </p>
            {post.content.split('\n\n').map((paragraph, index) => (
              <div key={index} className="mb-6">
                {paragraph.startsWith('###') ? (
                  <h3 className="font-playfair text-2xl mb-4">
                    {paragraph.replace('### ', '')}
                  </h3>
                ) : paragraph.startsWith('- ') ? (
                  <ul className="list-disc list-inside space-y-2">
                    {paragraph.split('\n').map((item, i) => (
                      <li key={i} className="text-gray-800">
                        {item.replace('- ', '')}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-gray-800 leading-relaxed">
                    {paragraph.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')}
                  </p>
                )}
              </div>
            ))}
          </div>

          {/* Share Section */}
          <div className="mt-12 pt-8 border-t">
            <div className="flex items-center justify-between">
              <div>
                <h4 className="font-playfair text-xl mb-2">Share this article</h4>
                <p className="text-gray-600">Help spread the knowledge</p>
              </div>
              <button 
                onClick={handleShare}
                className="flex items-center space-x-2 text-[#FFD700] hover:text-[#E5C100]"
              >
                <Share2 className="w-5 h-5" />
                <span>Share</span>
              </button>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default BlogPost;