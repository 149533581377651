import React, { useState, useEffect } from 'react';
import { Shield, Lock, Globe, Star, ChevronRight, Calendar, Clock, CheckCircle, Search, FileCheck } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabaseClient';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import TestimonialCarousel from '../components/TestimonialCarousel';
import AuthenticationServiceModal from '../components/AuthenticationServiceModal';

type BlogPost = {
  id: string;
  title: string;
  excerpt: string;
  category: string;
  image_url: string;
  author: string;
  reading_time: number;
  published_at: string;
};

function Home() {
  const [latestPosts, setLatestPosts] = useState<BlogPost[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchLatestPosts();
  }, []);

  const fetchLatestPosts = async () => {
    try {
      const { data, error } = await supabase
        .from('blog_posts')
        .select('*')
        .order('published_at', { ascending: false })
        .limit(3);
      
      if (error) throw error;
      setLatestPosts(data || []);
    } catch (error) {
      console.error('Error fetching blog posts:', error);
    }
  };

  const handleExploreProducts = () => {
    navigate('/shop');
  };

  const handleStartSelling = () => {
    navigate('/sell');
  };

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      
      {/* Hero Section */}
      <section className="relative h-[90vh] flex items-center justify-center">
        <div 
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: 'url("https://images.unsplash.com/photo-1526045431048-f857369baa09?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="absolute inset-0 bg-black/60"></div>
        </div>
        
        <div className="relative z-10 text-center text-white px-4 max-w-5xl mx-auto">
          <h1 className="font-playfair text-6xl md:text-7xl mb-4">
            Trusted. Verified. Luxurious.
          </h1>
          <p className="font-lato text-xl md:text-2xl mb-12 max-w-3xl mx-auto">
            Buy and sell pre-owned luxury goods with confidence.
          </p>
          <div className="flex flex-col sm:flex-row gap-6 justify-center">
            <button 
              onClick={handleExploreProducts}
              className="bg-[#FFD700] text-black px-8 py-4 rounded-md font-semibold hover:bg-[#E5C100] transition-colors text-lg"
            >
              Explore Products
            </button>
            <button 
              onClick={handleStartSelling}
              className="border-2 border-[#FFD700] text-[#FFD700] px-8 py-4 rounded-md font-semibold hover:bg-[#FFD700] hover:text-black transition-colors text-lg"
            >
              Start Selling
            </button>
          </div>
        </div>
      </section>

      {/* Primary Service Section */}
      <section className="py-20 px-4 bg-luxury-900">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div className="space-y-6">
              <h2 className="font-playfair text-4xl md:text-5xl text-white">
                Expert Authentication Services
              </h2>
              <p className="text-xl text-gray-300">
                We specialize in comprehensive inspection, verification, and authenticity validation services for luxury goods.
              </p>
              <div className="space-y-4">
                <div className="flex items-start space-x-3">
                  <Search className="w-6 h-6 text-gold-300 mt-1 flex-shrink-0" />
                  <p className="text-gray-300">Detailed physical inspection by certified experts</p>
                </div>
                <div className="flex items-start space-x-3">
                  <FileCheck className="w-6 h-6 text-gold-300 mt-1 flex-shrink-0" />
                  <p className="text-gray-300">Comprehensive documentation verification</p>
                </div>
                <div className="flex items-start space-x-3">
                  <CheckCircle className="w-6 h-6 text-gold-300 mt-1 flex-shrink-0" />
                  <p className="text-gray-300">Authentication certificate issuance</p>
                </div>
              </div>
              <div className="bg-luxury-800 p-6 rounded-lg border border-gold-300/20">
                <div className="flex justify-between items-center mb-3">
                  <span className="text-white font-playfair text-xl">Service Fee</span>
                  <span className="text-gold-300 font-bold text-2xl">₹2,999</span>
                </div>
                <p className="text-gray-400 text-sm">+ 18% GST applicable</p>
                <div className="mt-4 pt-4 border-t border-luxury-700">
                  <button
                    onClick={() => setIsModalOpen(true)}
                    className="btn-primary w-full text-center inline-block"
                  >
                    Book Authentication Service
                  </button>
                </div>
              </div>
            </div>
            <div className="relative">
              <div className="aspect-square rounded-lg overflow-hidden">
                <img
                  src="https://images.unsplash.com/photo-1617870952348-7524edfb61b7?auto=format&fit=crop&w=800&q=80"
                  alt="Authentication Process"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="absolute -bottom-6 -right-6 bg-white p-6 rounded-lg shadow-luxury max-w-xs">
                <div className="flex items-center space-x-3 mb-3">
                  <Shield className="w-8 h-8 text-gold-300" />
                  <h3 className="font-playfair text-lg">100% Authentic</h3>
                </div>
                <p className="text-gray-600">
                  Every item undergoes our rigorous authentication process
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Key Features Section */}
      <section className="py-24 px-4 bg-gray-50">
        <div className="max-w-7xl mx-auto">
          <h2 className="font-playfair text-4xl text-center mb-16">Why Choose WebInfoIT</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <FeatureCard 
              Icon={Shield}
              title="End-to-End Verification"
              description="Every item is meticulously inspected for authenticity."
            />
            <FeatureCard 
              Icon={Lock}
              title="Secure Transactions"
              description="Your payments are safe with our escrow system."
            />
            <FeatureCard 
              Icon={Globe}
              title="Global Reach"
              description="Expanding to international markets soon."
            />
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="py-16 bg-black text-white">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
            <StatCard number="42%" text="Return Customer Rate" />
            <StatCard number="100%" text="Authentic Products" />
            <StatCard number="24/7" text="Customer Support" />
          </div>
        </div>
      </section>

      {/* Latest Blog Posts Section */}
      <section className="py-24 px-4 bg-gray-50">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="font-playfair text-4xl mb-4">Latest Insights</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Stay informed with our expert insights on luxury authentication, market trends, and investment opportunities
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
            {latestPosts.map((post) => (
              <div key={post.id} className="bg-white rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow">
                <div className="relative h-48">
                  <img
                    src={post.image_url}
                    alt={post.title}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute top-4 left-4">
                    <span className="bg-[#FFD700]/10 text-[#FFD700] px-3 py-1 rounded-full text-sm font-semibold">
                      {post.category}
                    </span>
                  </div>
                </div>
                <div className="p-6">
                  <div className="flex items-center space-x-4 mb-4 text-sm text-gray-500">
                    <div className="flex items-center">
                      <Calendar className="w-4 h-4 mr-1" />
                      {new Date(post.published_at).toLocaleDateString()}
                    </div>
                    <div className="flex items-center">
                      <Clock className="w-4 h-4 mr-1" />
                      {post.reading_time} min read
                    </div>
                  </div>
                  <h3 className="font-playfair text-xl mb-2">{post.title}</h3>
                  <p className="text-gray-600 mb-4 line-clamp-2">{post.excerpt}</p>
                  <div className="flex items-center justify-between">
                    <span className="text-sm font-semibold">{post.author}</span>
                    <Link
                      to="/blog"
                      className="flex items-center space-x-2 text-[#FFD700] hover:text-[#E5C100] transition-colors"
                    >
                      <span>Read More</span>
                      <ChevronRight className="w-4 h-4" />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="text-center">
            <Link
              to="/blog"
              className="inline-flex items-center space-x-2 bg-[#FFD700] text-black px-8 py-3 rounded-md font-semibold hover:bg-[#E5C100] transition-colors"
            >
              <span>View All Articles</span>
              <ChevronRight className="w-5 h-5" />
            </Link>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-24 px-4">
        <div className="max-w-7xl mx-auto">
          <h2 className="font-playfair text-4xl text-center mb-4">What Our Clients Say</h2>
          <p className="text-center text-gray-600 mb-16 max-w-2xl mx-auto">
            Join thousands of satisfied customers who trust WebInfoIT for their luxury goods transactions.
          </p>
          <TestimonialCarousel />
        </div>
      </section>

      <Footer />

      <AuthenticationServiceModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}

function FeatureCard({ Icon, title, description }) {
  return (
    <div className="text-center p-8 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow">
      <div className="inline-block p-4 bg-[#FFD700]/10 rounded-full mb-6">
        <Icon className="w-8 h-8 text-[#FFD700]" />
      </div>
      <h3 className="font-playfair text-2xl mb-4">{title}</h3>
      <p className="font-lato text-gray-600 text-lg">{description}</p>
    </div>
  );
}

function StatCard({ number, text }) {
  return (
    <div className="p-6">
      <p className="font-playfair text-4xl text-[#FFD700] mb-2">{number}</p>
      <p className="font-lato text-gray-300">{text}</p>
    </div>
  );
}

export default Home;