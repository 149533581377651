import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { RotateCcw, CheckCircle, AlertTriangle, Clock } from 'lucide-react';

const ReturnPolicy = () => {
  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      
      {/* Hero Section */}
      <section className="relative h-[40vh] flex items-center justify-center">
        <div 
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: 'url("https://images.unsplash.com/photo-1512909006721-3d6018887383?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="absolute inset-0 bg-black/50"></div>
        </div>
        <div className="relative z-10 text-center text-white px-4">
          <h1 className="font-playfair text-5xl md:text-6xl mb-4">Return Policy</h1>
          <p className="font-lato text-xl max-w-2xl mx-auto">
            Understanding our return process and guidelines
          </p>
        </div>
      </section>

      {/* Content Section */}
      <section className="py-16 px-4">
        <div className="max-w-4xl mx-auto">
          <div className="prose prose-lg">
            <div className="mb-8">
              <p className="text-gray-600">
                At WebInfoIT, we strive to provide a seamless shopping experience. If you are not completely satisfied with your purchase, you may return eligible items by following our return policy guidelines.
              </p>
            </div>

            <div className="mb-12">
              <div className="flex items-center space-x-3 mb-6">
                <Clock className="w-8 h-8 text-[#FFD700]" />
                <h2 className="font-playfair text-3xl m-0">Return Window</h2>
              </div>
              <p>We offer a structured return policy to ensure customer satisfaction:</p>
              <ul>
                <li><strong>3-Day Inspection Period:</strong> After receiving your order, you have three days to inspect the item and report any issues.</li>
                <li><strong>7-Day Return Window:</strong> If your item is authenticated and meets return eligibility criteria, you may return it within seven days of delivery.</li>
                <li><strong>Immediate Returns for Non-Authentic Items:</strong> If an item is found to be non-authentic, you may return it immediately without any return window constraints.</li>
                <li><strong>Extended Return Window for Hidden Defects:</strong> If you discover a hidden defect after the initial inspection period, you may still be eligible for a return under certain conditions. Please contact our customer support team for assistance.</li>
              </ul>
            </div>

            <div className="mb-12">
              <div className="flex items-center space-x-3 mb-6">
                <CheckCircle className="w-8 h-8 text-[#FFD700]" />
                <h2 className="font-playfair text-3xl m-0">Eligible Returns</h2>
              </div>
              <p>To qualify for a return, items must meet the following criteria:</p>
              <ul>
                <li>The item must be in <strong>unused condition</strong> with all original tags attached.</li>
                <li>All <strong>original packaging and accessories</strong> (such as manuals, warranty cards, and protective cases) must be included.</li>
                <li>Any provided <strong>authentication certificates must remain intact</strong> and unaltered.</li>
                <li>The item should show <strong>no signs of wear, damage, or alterations</strong>.</li>
              </ul>
            </div>

            <div className="mb-12">
              <div className="flex items-center space-x-3 mb-6">
                <AlertTriangle className="w-8 h-8 text-[#FFD700]" />
                <h2 className="font-playfair text-3xl m-0">Non-Returnable Items</h2>
              </div>
              <p>Certain items are <strong>not eligible for return</strong>, including:</p>
              <ul>
                <li>Items with <strong>removed tags, broken seals, or missing packaging</strong>.</li>
                <li>Items that have been <strong>used, worn, or altered</strong> in any way.</li>
                <li>Items missing <strong>original accessories or authentication certificates</strong>.</li>
                <li><strong>Customized or personalized items</strong> that were specifically made for the customer.</li>
              </ul>
            </div>

            <div className="mb-12">
              <div className="flex items-center space-x-3 mb-6">
                <RotateCcw className="w-8 h-8 text-[#FFD700]" />
                <h2 className="font-playfair text-3xl m-0">Return Process</h2>
              </div>
              <p>If your item qualifies for a return, please follow these steps:</p>
              <ol>
                <li><strong>Contact Customer Support:</strong> Reach out to our customer service team at returns@webinfoit.com within the return window to request a return authorization.</li>
                <li><strong>Receive Return Authorization:</strong> Our team will provide a return authorization number and instructions for returning your item.</li>
                <li><strong>Schedule a Secure Pickup:</strong> We offer a hassle-free pickup service for returns. Once your return is authorized, our logistics partner will schedule a secure pickup from your location.</li>
                <li><strong>Item Inspection at Our Facility:</strong> After receiving your returned item, our team will inspect it to ensure it meets our return criteria. This process may take up to 5 business days.</li>
                <li><strong>Refund Processing:</strong> Once the item passes inspection, we will process your refund. Refunds will be issued to the original payment method within 7–10 business days.</li>
              </ol>
            </div>

            <div className="mb-12 bg-gray-50 p-6 rounded-lg">
              <h3 className="font-playfair text-2xl mb-4">Important Notes</h3>
              <ul className="space-y-2">
                <li>Shipping charges for returns may be applicable in some cases unless the return is due to a product defect or incorrect item received.</li>
                <li>Refunds will only be processed once the item has been inspected and approved for return.</li>
                <li>If the return is rejected due to non-compliance with our return policy, the item will be shipped back to the customer.</li>
              </ul>
            </div>

            <div className="bg-gray-50 p-8 rounded-lg">
              <h3 className="font-playfair text-2xl mb-4">Contact Information</h3>
              <p>For return-related queries, please contact us at:</p>
              <p className="font-semibold mb-2">returns@webinfoit.com</p>
              <p className="text-gray-600">
                Customer Service Hours:<br />
                Monday to Friday, 9 AM – 6 PM (Local Time)
              </p>
            </div>

            <div className="mt-8">
              <p className="text-gray-600 italic">
                We value your trust and aim to make your shopping experience as smooth as possible.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default ReturnPolicy;