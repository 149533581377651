import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Truck, Package, Clock, Shield } from 'lucide-react';

const ShippingPolicy = () => {
  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      
      {/* Hero Section */}
      <section className="relative h-[40vh] flex items-center justify-center">
        <div 
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: 'url("https://images.unsplash.com/photo-1566576912321-d58ddd7a6088?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="absolute inset-0 bg-black/50"></div>
        </div>
        <div className="relative z-10 text-center text-white px-4">
          <h1 className="font-playfair text-5xl md:text-6xl mb-4">Shipping Policy</h1>
          <p className="font-lato text-xl max-w-2xl mx-auto">
            Learn about our secure shipping process and delivery timelines
          </p>
        </div>
      </section>

      {/* Content Section */}
      <section className="py-16 px-4">
        <div className="max-w-4xl mx-auto">
          <div className="prose prose-lg">
            <div className="mb-12">
              <p className="text-gray-600 mb-8">
                At WebInfoIT, we are committed to providing a seamless and reliable shipping experience for all our customers. Our shipping process is designed to ensure that your luxury items reach you safely, securely, and within the expected time frame. From professional handling to full insurance coverage, we take every measure to protect your valuable purchases and deliver them with the utmost care.
              </p>
            </div>

            <div className="mb-12">
              <div className="flex items-center space-x-3 mb-6">
                <Truck className="w-8 h-8 text-[#FFD700]" />
                <h2 className="font-playfair text-3xl m-0">Shipping Process</h2>
              </div>
              <p>Our shipping process is meticulously structured to guarantee secure transportation of your luxury items:</p>
              <ul>
                <li>Professional pickup and handling</li>
                <li>Secure packaging with tamper-evident seals</li>
                <li>Full insurance coverage during transit</li>
                <li>Real-time tracking updates</li>
              </ul>
            </div>

            <div className="mb-12">
              <div className="flex items-center space-x-3 mb-6">
                <Clock className="w-8 h-8 text-[#FFD700]" />
                <h2 className="font-playfair text-3xl m-0">Delivery Timelines</h2>
              </div>
              <p>Our standard delivery timelines are:</p>
              <ul>
                <li>Authentication Process: 2-3 business days</li>
                <li>Domestic Shipping: 1-2 business days</li>
                <li>Metro Cities: Next-day delivery available</li>
                <li>International Shipping: 3-5 business days</li>
              </ul>
            </div>

            <div className="mb-12">
              <div className="flex items-center space-x-3 mb-6">
                <Package className="w-8 h-8 text-[#FFD700]" />
                <h2 className="font-playfair text-3xl m-0">Packaging Standards</h2>
              </div>
              <p>We maintain strict packaging standards to ensure item safety:</p>
              <ul>
                <li>Custom-fitted protective boxes</li>
                <li>Multi-layer cushioning</li>
                <li>Moisture-proof packaging</li>
                <li>Security seals with unique identifiers</li>
              </ul>
            </div>

            <div className="mb-12">
              <div className="flex items-center space-x-3 mb-6">
                <Shield className="w-8 h-8 text-[#FFD700]" />
                <h2 className="font-playfair text-3xl m-0">Insurance Coverage</h2>
              </div>
              <p>All shipments are fully insured against:</p>
              <ul>
                <li>Loss or theft during transit</li>
                <li>Damage during handling</li>
                <li>Customs-related issues</li>
                <li>Delivery delays</li>
              </ul>
              <p className="mt-4">
                Customers are encouraged to report any issues related to loss, damage, or delay within <strong>48 hours</strong> of receiving the package to facilitate a swift resolution.
              </p>
            </div>

            <div className="mb-12">
              <h2 className="font-playfair text-3xl mb-6">Shipping Charges and Payment Options</h2>
              <p>
                Shipping charges vary based on the destination, package weight, and selected delivery speed. We offer:
              </p>
              <ul>
                <li>Standard shipping at nominal cost</li>
                <li>Express shipping option available</li>
                <li>International shipping with customs handling</li>
                <li>Multiple payment methods accepted</li>
              </ul>
            </div>

            <div className="mb-12">
              <h2 className="font-playfair text-3xl mb-6">Returns and Refunds for Shipping Issues</h2>
              <p>
                If a shipment is lost, damaged, or does not arrive within the promised timeline, we offer a hassle-free return and refund process:
              </p>
              <ul>
                <li>Report damages within 48 hours with photographic evidence</li>
                <li>Full investigation for delayed deliveries</li>
                <li>Complete refund or replacement for lost items</li>
              </ul>
            </div>

            <div className="bg-gray-50 p-8 rounded-lg">
              <h3 className="font-playfair text-2xl mb-4">Contact Information</h3>
              <p>For shipping-related queries, please contact us at:</p>
              <p className="font-semibold mb-2">shipping@webinfoit.com</p>
              <p className="text-gray-600">
                Customer Support Hours:<br />
                Monday to Friday, 9:00 AM – 6:00 PM (IST)
              </p>
            </div>

            <div className="mt-12">
              <h2 className="font-playfair text-3xl mb-6">Final Note</h2>
              <p>
                WebInfoIT continuously strives to improve our shipping policies and logistics operations to provide a seamless shopping experience. We value customer feedback and regularly assess our shipping processes to enhance efficiency and reliability. By choosing WebInfoIT, you can trust that your luxury items will be handled with care and delivered securely.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default ShippingPolicy;