import React, { useState, useEffect } from 'react';
import { supabase } from '../lib/supabaseClient';
import { Package, CreditCard, MapPin, Truck, LogIn, Ban as Bank } from 'lucide-react';
import OrderConfirmation from './OrderConfirmation';

interface CheckoutFormProps {
  isOpen: boolean;
  onClose: () => void;
  product: any;
  quantity: number;
}

const bankDetails = {
  accountName: "WebInfoIT Private Limited",
  accountNumber: "123456786442",
  ifscCode: "43894839",
  bankName: "HDFC Bank"
};

const CheckoutForm: React.FC<CheckoutFormProps> = ({ isOpen, onClose, product, quantity }) => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [newAddress, setNewAddress] = useState({
    full_name: '',
    address_line1: '',
    address_line2: '',
    city: '',
    state: '',
    postal_code: '',
    phone: ''
  });

  useEffect(() => {
    if (isOpen) {
      checkAuth();
    }
  }, [isOpen]);

  const checkAuth = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      setIsAuthenticated(!!user);
      if (user) {
        fetchAddresses();
      }
      return !!user;
    } catch (err) {
      console.error('Auth check error:', err);
      setIsAuthenticated(false);
      return false;
    }
  };

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password
      });

      if (error) throw error;

      setIsAuthenticated(true);
      await fetchAddresses();
    } catch (err) {
      console.error('Sign in error:', err);
      setError(err.message || 'Failed to sign in. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: window.location.origin
        }
      });

      if (error) throw error;

      setIsAuthenticated(true);
      await fetchAddresses();
    } catch (err) {
      console.error('Sign up error:', err);
      setError(err.message || 'Failed to sign up. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const fetchAddresses = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase
        .from('shipping_addresses')
        .select('*')
        .eq('user_id', user.id)
        .order('is_default', { ascending: false });

      if (error) throw error;
      setAddresses(data || []);
      if (data && data.length > 0) {
        setSelectedAddress(data.find(addr => addr.is_default) || data[0]);
      }
    } catch (error) {
      console.error('Error fetching addresses:', error);
      setError('Failed to load saved addresses');
    }
  };

  const handleAddressSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const isAuthenticated = await checkAuth();
      if (!isAuthenticated) {
        setError('Please sign in to continue');
        return;
      }

      const { data: { user } } = await supabase.auth.getUser();
      
      const requiredFields = ['full_name', 'address_line1', 'city', 'state', 'postal_code', 'phone'];
      const missingFields = requiredFields.filter(field => !newAddress[field]);
      
      if (missingFields.length > 0) {
        throw new Error(`Please fill in all required fields: ${missingFields.join(', ')}`);
      }

      const { data, error } = await supabase
        .from('shipping_addresses')
        .insert([{
          user_id: user.id,
          ...newAddress,
          is_default: addresses.length === 0,
          country: 'India'
        }])
        .select()
        .single();

      if (error) throw error;

      setAddresses([...addresses, data]);
      setSelectedAddress(data);
      
      setNewAddress({
        full_name: '',
        address_line1: '',
        address_line2: '',
        city: '',
        state: '',
        postal_code: '',
        phone: ''
      });

      setStep(2);
    } catch (err) {
      console.error('Error saving address:', err);
      setError(err.message || 'Error saving address. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const calculateTotals = () => {
    const subtotal = product.price * quantity;
    const shipping = 0; // Free shipping
    const tax = subtotal * 0.18; // 18% GST
    const total = subtotal + shipping + tax;

    return {
      subtotal,
      shipping,
      tax,
      total
    };
  };

  const createOrder = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Please sign in to continue');

      const { subtotal, shipping, tax, total } = calculateTotals();

      const { data: order, error: orderError } = await supabase
        .from('orders')
        .insert([{
          user_id: user.id,
          shipping_address_id: selectedAddress.id,
          payment_method: paymentMethod,
          subtotal,
          shipping_fee: shipping,
          tax,
          total,
          status: 'pending',
          payment_status: 'pending'
        }])
        .select()
        .single();

      if (orderError) throw orderError;

      const { error: itemError } = await supabase
        .from('order_items')
        .insert([{
          order_id: order.id,
          product_id: product.id,
          quantity,
          price: product.price,
          subtotal: product.price * quantity
        }]);

      if (itemError) throw itemError;

      setOrderId(order.id);
      return order.id;
    } catch (error) {
      console.error('Error creating order:', error);
      throw error;
    }
  };

  const handleConfirmOrder = async () => {
    try {
      setLoading(true);
      setError('');

      if (!paymentMethod) {
        setError('Please select a payment method');
        return;
      }

      const orderId = await createOrder();
      const { data: orderData } = await supabase
        .from('orders')
        .select('*, shipping_addresses(*)')
        .eq('id', orderId)
        .single();

      setOrderDetails(orderData);
      setShowConfirmation(true);
    } catch (err) {
      console.error('Error placing order:', err);
      setError('Failed to place order. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (showConfirmation && orderDetails) {
    return (
      <OrderConfirmation
        orderNumber={orderDetails.order_number}
        total={orderDetails.total}
        paymentMethod={orderDetails.payment_method}
        shippingAddress={orderDetails.shipping_addresses}
      />
    );
  }

  if (!isOpen) return null;

  const { subtotal, shipping, tax, total } = calculateTotals();

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity bg-black bg-opacity-75" onClick={onClose} />

        <div className="inline-block w-full max-w-2xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-lg shadow-xl">
          <div className="px-6 py-4 bg-gray-50 border-b">
            <div className="flex justify-between items-center">
              <h3 className="font-playfair text-2xl">Checkout</h3>
              <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
                ×
              </button>
            </div>
          </div>

          <div className="px-6 py-4">
            {!isAuthenticated ? (
              <div className="space-y-6">
                <div className="text-center">
                  <LogIn className="w-12 h-12 text-[#FFD700] mx-auto mb-4" />
                  <h4 className="font-playfair text-2xl mb-2">Sign In Required</h4>
                  <p className="text-gray-600 mb-6">
                    Please sign in or create an account to continue with checkout
                  </p>
                </div>

                {error && (
                  <div className="p-3 bg-red-50 text-red-700 rounded-md">
                    {error}
                  </div>
                )}

                <form onSubmit={handleSignIn} className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Email
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full p-2 border rounded-md focus:ring-2 focus:ring-[#FFD700] focus:border-transparent"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Password
                    </label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full p-2 border rounded-md focus:ring-2 focus:ring-[#FFD700] focus:border-transparent"
                      required
                    />
                  </div>
                  <div className="flex flex-col space-y-3">
                    <button
                      type="submit"
                      disabled={loading}
                      className="w-full bg-[#FFD700] text-black py-2 rounded-md hover:bg-[#E5C100] transition-colors disabled:opacity-50"
                    >
                      {loading ? 'Signing in...' : 'Sign In'}
                    </button>
                    <button
                      type="button"
                      onClick={handleSignUp}
                      disabled={loading}
                      className="w-full border-2 border-[#FFD700] text-[#FFD700] py-2 rounded-md hover:bg-[#FFD700] hover:text-black transition-colors disabled:opacity-50"
                    >
                      {loading ? 'Creating account...' : 'Create Account'}
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <>
                {/* Progress Steps */}
                <div className="flex justify-between mb-8">
                  <Step
                    number={1}
                    title="Shipping"
                    icon={Truck}
                    isActive={step === 1}
                    isCompleted={step > 1}
                  />
                  <Step
                    number={2}
                    title="Payment"
                    icon={CreditCard}
                    isActive={step === 2}
                    isCompleted={step > 2}
                  />
                </div>

                {error && (
                  <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-md">
                    {error}
                  </div>
                )}

                {step === 1 && (
                  <div>
                    <h4 className="font-playfair text-xl mb-4">Shipping Address</h4>
                    
                    {addresses.length > 0 && (
                      <div className="mb-6">
                        <div className="grid grid-cols-1 gap-4">
                          {addresses.map((address) => (
                            <label
                              key={address.id}
                              className={`relative border rounded-lg p-4 cursor-pointer ${
                                selectedAddress?.id === address.id
                                  ? 'border-[#FFD700] bg-[#FFD700]/5'
                                  : 'border-gray-200'
                              }`}
                            >
                              <input
                                type="radio"
                                name="address"
                                className="hidden"
                                checked={selectedAddress?.id === address.id}
                                onChange={() => setSelectedAddress(address)}
                              />
                              <div className="flex items-start">
                                <MapPin className="w-5 h-5 text-[#FFD700] mt-1 mr-3" />
                                <div>
                                  <p className="font-semibold">{address.full_name}</p>
                                  <p className="text-gray-600">
                                    {address.address_line1}
                                    {address.address_line2 && `, ${address.address_line2}`}
                                  </p>
                                  <p className="text-gray-600">
                                    {address.city}, {address.state} {address.postal_code}
                                  </p>
                                  <p className="text-gray-600">{address.phone}</p>
                                </div>
                              </div>
                            </label>
                          ))}
                        </div>
                      </div>
                    )}

                    <div className="mb-6">
                      <h4 className="font-playfair text-xl mb-4">Add New Address</h4>
                      <form onSubmit={handleAddressSubmit} className="grid grid-cols-2 gap-4">
                        <div className="col-span-2">
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Full Name *
                          </label>
                          <input
                            type="text"
                            required
                            value={newAddress.full_name}
                            onChange={(e) => setNewAddress({ ...newAddress, full_name: e.target.value })}
                            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-[#FFD700] focus:border-transparent"
                            placeholder="Enter your full name"
                          />
                        </div>
                        <div className="col-span-2">
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Address Line 1 *
                          </label>
                          <input
                            type="text"
                            required
                            value={newAddress.address_line1}
                            onChange={(e) => setNewAddress({ ...newAddress, address_line1: e.target.value })}
                            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-[#FFD700] focus:border-transparent"
                            placeholder="Street address"
                          />
                        </div>
                        <div className="col-span-2">
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Address Line 2
                          </label>
                          <input
                            type="text"
                            value={newAddress.address_line2}
                            onChange={(e) => setNewAddress({ ...newAddress, address_line2: e.target.value })}
                            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-[#FFD700] focus:border-transparent"
                            placeholder="Apartment, suite, etc. (optional)"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            City *
                          </label>
                          <input
                            type="text"
                            required
                            value={newAddress.city}
                            onChange={(e) => setNewAddress({ ...newAddress, city: e.target.value })}
                            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-[#FFD700] focus:border-transparent"
                            placeholder="Enter city"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            State *
                          </label>
                          <input
                            type="text"
                            required
                            value={newAddress.state}
                            onChange={(e) => setNewAddress({ ...newAddress, state: e.target.value })}
                            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-[#FFD700] focus:border-transparent"
                            placeholder="Enter state"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Postal Code *
                          </label>
                          <input
                            type="text"
                            required
                            value={newAddress.postal_code}
                            onChange={(e) => setNewAddress({ ...newAddress, postal_code: e.target.value })}
                            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-[#FFD700] focus:border-transparent"
                            placeholder="Enter postal code"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Phone *
                          </label>
                          <input
                            type="tel"
                            required
                            value={newAddress.phone}
                            onChange={(e) => setNewAddress({ ...newAddress, phone: e.target.value })}
                            className="w-full p-2 border rounded-md focus:ring-2 focus:ring-[#FFD700] focus:border-transparent"
                            placeholder="Enter phone number"
                          />
                        </div>
                        <div className="col-span-2">
                          <button
                            type="submit"
                            disabled={loading}
                            className="w-full bg-[#FFD700] text-black py-2 rounded-md hover:bg-[#E5C100] transition-colors disabled:opacity-50"
                          >
                            {loading ? 'Saving...' : 'Save Address'}
                          </button>
                        </div>
                      </form>
                    </div>

                    <div className="flex justify-between">
                      <button
                        onClick={onClose}
                        className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => setStep(2)}
                        disabled={!selectedAddress}
                        className="px-4 py-2 bg-[#FFD700] text-black rounded-md hover:bg-[#E5C100] disabled:opacity-50"
                      >
                        Continue to Payment
                      </button>
                    </div>
                  </div>
                )}

                {step === 2 && (
                  <div>
                    <h4 className="font-playfair text-xl mb-4">Order Summary</h4>
                    <div className="bg-gray-50 p-4 rounded-lg mb-6">
                      <div className="flex items-center justify-between mb-4">
                        <div>
                          <h5 className="font-semibold">{product.name}</h5>
                          <p className="text-gray-600">Quantity: {quantity}</p>
                        </div>
                        <p className="font-semibold">₹{subtotal.toLocaleString()}</p>
                      </div>
                      <div className="border-t pt-4">
                        <div className="flex justify-between mb-2">
                          <span>Subtotal</span>
                          <span>₹{subtotal.toLocaleString()}</span>
                        </div>
                        <div className="flex justify-between mb-2">
                          <span>Shipping</span>
                          <span>{shipping === 0 ? 'Free' : `₹${shipping.toLocaleString()}`}</span>
                        </div>
                        <div className="flex justify-between mb-2">
                          <span>GST (18%)</span>
                          <span>₹{tax.toLocaleString()}</span>
                        </div>
                        <div className="flex justify-between font-semibold text-lg border-t pt-2">
                          <span>Total</span>
                          <span>₹{total.toLocaleString()}</span>
                        </div>
                      </div>
                    </div>

                    <div className="mb-6">
                      <h4 className="font-playfair text-xl mb-4">Payment Method</h4>
                      <div className="space-y-4">
                        {/* Bank Transfer Option */}
                        <label className="block border rounded-lg p-4 cursor-pointer hover:border-[#FFD700] transition-colors">
                          <div className="flex items-start">
                            <input
                              type="radio"
                              name="payment"
                              value="bank_transfer"
                              checked={paymentMethod === 'bank_transfer'}
                              onChange={(e) => setPaymentMethod(e.target.value)}
                              className="mt-1 mr-3"
                            />
                            <div className="flex-1">
                              <div className="flex items-center mb-2">
                                <Bank className="w-5 h-5 text-[#FFD700] mr-2" />
                                <span className="font-semibold">Bank Transfer</span>
                              </div>
                              {paymentMethod === 'bank_transfer' && (
                                <div className="mt-2 p-3 bg-gray-50 rounded-md text-sm">
                                  <p className="font-semibold mb-1">Bank Details:</p>
                                  <p>Account Name: {bankDetails.accountName}</p>
                                  <p>Account Number: {bankDetails.accountNumber}</p>
                                  <p>IFSC Code: {bankDetails.ifscCode}</p>
                                  <p>Bank Name: {bankDetails.bankName}</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </label>

                        {/* Cash on Delivery Option */}
                        <label className="block border rounded-lg p-4 cursor-pointer hover:border-[#FFD700] transition-colors">
                          <div className="flex items-start">
                            <input
                              type="radio"
                              name="payment"
                              value="cod"
                              checked={paymentMethod === 'cod'}
                              onChange={(e) => setPaymentMethod(e.target.value)}
                              className="mt-1 mr-3"
                            />
                            <div className="flex items-center">
                              <CreditCard className="w-5 h-5 text-[#FFD700] mr-2" />
                              <span className="font-semibold">Cash on Delivery</span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>

                    <div className="flex justify-between">
                      <button
                        onClick={() => setStep(1)}
                        className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
                      >
                        Back
                      </button>
                      <button
                        onClick={handleConfirmOrder}
                        disabled={loading || !paymentMethod}
                        className="px-4 py-2 bg-[#FFD700] text-black rounded-md hover:bg-[#E5C100] disabled:opacity-50"
                      >
                        {loading ? 'Processing...' : 'Confirm Order'}
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Step = ({ number, title, icon: Icon, isActive, isCompleted }) => {
  return (
    <div className="flex flex-col items-center">
      <div
        className={`w-10 h-10 rounded-full flex items-center justify-center mb-2 ${
          isActive
            ? 'bg-[#FFD700] text-black'
            : isCompleted
            ? 'bg-green-500 text-white'
            : 'bg-gray-200 text-gray-500'
        }`}
      >
        {isCompleted ? (
          <Icon className="w-5 h-5" />
        ) : (
          <span className="font-semibold">{number}</span>
        )}
      </div>
      <span className={`text-sm ${isActive ? 'text-[#FFD700] font-semibold' : 'text-gray-500'}`}>
        {title}
      </span>
    </div>
  );
};

export default CheckoutForm;