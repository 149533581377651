import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Shield, Clock, CreditCard, Package, Truck, DollarSign } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

type FAQItem = {
  question: string;
  answer: string;
  icon?: React.ElementType;
};

const FAQ = () => {
  const [openBuyerIndex, setOpenBuyerIndex] = useState<number | null>(0);
  const [openSellerIndex, setOpenSellerIndex] = useState<number | null>(0);

  const buyerFAQs: FAQItem[] = [
    {
      question: "How do I know the product is authentic?",
      answer: "Every item undergoes a rigorous authentication process by our certified experts. We use advanced technology and multi-point inspection to verify authenticity. Each purchase includes a detailed verification report and authenticity certificate.",
      icon: Shield
    },
    {
      question: "What happens if I'm not satisfied with my purchase?",
      answer: "We offer a 3-day inspection period after delivery. If you're not completely satisfied, you can return the item in its original condition for a full refund. We'll arrange secure pickup and handle the return process.",
      icon: Package
    },
    {
      question: "How is payment processed?",
      answer: "We use a secure escrow system. Your payment is held safely until you receive and approve the item. This ensures a safe transaction for both buyers and sellers.",
      icon: CreditCard
    },
    {
      question: "What is covered by your insurance?",
      answer: "All items are fully insured during transit and authentication. Our comprehensive insurance covers loss, damage, and authenticity guarantees.",
      icon: Shield
    }
  ];

  const sellerFAQs: FAQItem[] = [
    {
      question: "How long does the verification process take?",
      answer: "Our verification process typically takes 2-3 business days from the time we receive your item. This includes detailed authentication, condition assessment, and documentation.",
      icon: Clock
    },
    {
      question: "When will I receive payment?",
      answer: "Payment is released within 24 hours after the buyer confirms receipt and satisfaction with the item. Funds are transferred directly to your designated bank account.",
      icon: DollarSign
    },
    {
      question: "How is the pickup arranged?",
      answer: "Once your listing is approved, we'll coordinate a convenient pickup time. Our secure courier service handles collection with full insurance coverage.",
      icon: Truck
    },
    {
      question: "What fees are involved in selling?",
      answer: "We charge a competitive commission based on the final sale price. This includes authentication, secure handling, insurance, and marketing services.",
      icon: DollarSign
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      
      {/* Hero Section */}
      <section className="relative h-[40vh] flex items-center justify-center">
        <div 
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: 'url("https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="absolute inset-0 bg-black/50"></div>
        </div>
        <div className="relative z-10 text-center text-white px-4">
          <h1 className="font-playfair text-5xl md:text-6xl mb-4">Frequently Asked Questions</h1>
          <p className="font-lato text-xl max-w-2xl mx-auto">
            Find answers to common questions about buying and selling luxury items
          </p>
        </div>
      </section>

      {/* FAQ Content */}
      <section className="py-20 px-4">
        <div className="max-w-4xl mx-auto">
          {/* Buyer FAQs */}
          <div className="mb-16">
            <h2 className="font-playfair text-3xl mb-8 text-center">For Buyers</h2>
            <div className="space-y-4">
              {buyerFAQs.map((faq, index) => (
                <FAQAccordion
                  key={index}
                  item={faq}
                  isOpen={openBuyerIndex === index}
                  onClick={() => setOpenBuyerIndex(openBuyerIndex === index ? null : index)}
                />
              ))}
            </div>
          </div>

          {/* Seller FAQs */}
          <div>
            <h2 className="font-playfair text-3xl mb-8 text-center">For Sellers</h2>
            <div className="space-y-4">
              {sellerFAQs.map((faq, index) => (
                <FAQAccordion
                  key={index}
                  item={faq}
                  isOpen={openSellerIndex === index}
                  onClick={() => setOpenSellerIndex(openSellerIndex === index ? null : index)}
                />
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 px-4 bg-gray-50">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="font-playfair text-3xl mb-6">Still Have Questions?</h2>
          <p className="font-lato text-gray-600 mb-8">
            Our luxury experts are here to help you with any additional questions
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <button className="bg-[#FFD700] text-black px-8 py-3 rounded-md font-semibold hover:bg-[#E5C100] transition-colors">
              Contact Support
            </button>
            <button className="border-2 border-[#FFD700] text-black px-8 py-3 rounded-md font-semibold hover:bg-[#FFD700] transition-colors">
              Live Chat
            </button>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

const FAQAccordion = ({ item, isOpen, onClick }: { item: FAQItem; isOpen: boolean; onClick: () => void }) => {
  const Icon = item.icon;
  
  return (
    <div className="border border-gray-200 rounded-lg overflow-hidden">
      <button
        className="w-full px-6 py-4 flex items-center justify-between bg-white hover:bg-gray-50 transition-colors"
        onClick={onClick}
      >
        <div className="flex items-center space-x-4">
          {Icon && <Icon className="w-6 h-6 text-[#FFD700]" />}
          <span className="font-playfair text-lg text-left">{item.question}</span>
        </div>
        {isOpen ? (
          <ChevronUp className="w-5 h-5 text-gray-500" />
        ) : (
          <ChevronDown className="w-5 h-5 text-gray-500" />
        )}
      </button>
      
      {isOpen && (
        <div className="px-6 py-4 bg-gray-50">
          <p className="font-lato text-gray-600">{item.answer}</p>
        </div>
      )}
    </div>
  );
};

export default FAQ;