import React, { useState } from 'react';
import { Shield, DollarSign, Truck, Globe, Users, Clock, Package, Search, FileCheck, Truck as TruckDelivery } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const SellWithUs = () => {
  const [formData, setFormData] = useState({
    itemType: '',
    brand: '',
    model: '',
    condition: '',
    description: '',
    askingPrice: '',
    name: '',
    email: '',
    phone: '',
    preferredDate: '',
    preferredTime: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission
    console.log(formData);
  };

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      
      {/* Hero Section */}
      <section className="relative h-[60vh] flex items-center justify-center">
        <div 
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: 'url("https://images.unsplash.com/photo-1490367532201-b9bc1dc483f6?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="absolute inset-0 bg-black/50"></div>
        </div>
        <div className="relative z-10 text-center text-white px-4 max-w-5xl mx-auto">
          <h1 className="font-playfair text-5xl md:text-6xl mb-6">Sell Your Luxury Items with Confidence</h1>
          <p className="font-lato text-xl md:text-2xl max-w-3xl mx-auto">
            Get the best value for your pre-owned luxury goods with our trusted platform
          </p>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-20 px-4 bg-gray-50">
        <div className="max-w-7xl mx-auto">
          <h2 className="font-playfair text-4xl text-center mb-16">Why Sell With Us</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <BenefitCard
              Icon={Users}
              title="Global Reach"
              description="Access a curated network of luxury buyers worldwide, ensuring the best value for your items."
            />
            <BenefitCard
              Icon={Shield}
              title="Secure Process"
              description="Your items are fully insured and payments are held in escrow until verification is complete."
            />
            <BenefitCard
              Icon={Clock}
              title="Quick & Easy"
              description="We handle everything from pickup to authentication, making selling effortless."
            />
          </div>
        </div>
      </section>

      {/* Verification Process Section */}
      <section className="py-20 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="font-playfair text-4xl mb-4">Our Verification Process</h2>
            <p className="font-lato text-xl text-gray-600">Ensuring authenticity and quality</p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <VerificationStep
              Icon={Truck}
              number="1"
              title="Pickup"
              description="Professional collection of your item with secure handling and insurance coverage."
            />
            <VerificationStep
              Icon={Search}
              number="2"
              title="Inspection"
              description="Thorough authentication check by certified experts using advanced technology."
            />
            <VerificationStep
              Icon={FileCheck}
              number="3"
              title="Verification Report"
              description="Detailed condition report with high-resolution images and authentication certificate."
            />
            <VerificationStep
              Icon={TruckDelivery}
              number="4"
              title="Delivery"
              description="Secure delivery to the buyer with tracking and insurance."
            />
          </div>

          <div className="mt-16 bg-gray-50 rounded-xl p-8 md:p-12">
            <div className="max-w-4xl mx-auto">
              <h3 className="font-playfair text-2xl mb-6 text-center">Our Authentication Standards</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0">
                    <Shield className="w-6 h-6 text-[#FFD700]" />
                  </div>
                  <div>
                    <h4 className="font-semibold mb-2">Expert Verification</h4>
                    <p className="text-gray-600">Each item is examined by certified authenticators with years of experience in luxury goods.</p>
                  </div>
                </div>
                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0">
                    <Search className="w-6 h-6 text-[#FFD700]" />
                  </div>
                  <div>
                    <h4 className="font-semibold mb-2">Multi-Point Inspection</h4>
                    <p className="text-gray-600">Comprehensive examination of materials, craftsmanship, and serial numbers.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Selling Process */}
      <section className="py-20 px-4 bg-gray-50">
        <div className="max-w-7xl mx-auto">
          <h2 className="font-playfair text-4xl text-center mb-16">How It Works</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <ProcessStep
              number="1"
              title="List Your Item"
              description="Fill out our simple form with your item's details and photos."
            />
            <ProcessStep
              number="2"
              title="Schedule Pickup"
              description="Choose a convenient time for our secure courier service."
            />
            <ProcessStep
              number="3"
              title="Get Paid"
              description="Receive payment quickly once your item is verified and sold."
            />
          </div>
        </div>
      </section>

      {/* Seller Form */}
      <section className="py-20 px-4 bg-gray-50">
        <div className="max-w-3xl mx-auto">
          <h2 className="font-playfair text-4xl text-center mb-16">Start Selling Now</h2>
          <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow-xl p-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Item Details */}
              <div className="md:col-span-2">
                <h3 className="font-playfair text-2xl mb-4">Item Details</h3>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Item Type</label>
                <select
                  name="itemType"
                  value={formData.itemType}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#FFD700]"
                >
                  <option value="">Select Type</option>
                  <option value="watch">Watch</option>
                  <option value="handbag">Handbag</option>
                  <option value="jewelry">Jewelry</option>
                  <option value="accessory">Accessory</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Brand</label>
                <input
                  type="text"
                  name="brand"
                  value={formData.brand}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#FFD700]"
                  placeholder="e.g., Rolex, Louis Vuitton"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Model/Style</label>
                <input
                  type="text"
                  name="model"
                  value={formData.model}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#FFD700]"
                  placeholder="e.g., Submariner, Neverfull"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Condition</label>
                <select
                  name="condition"
                  value={formData.condition}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#FFD700]"
                >
                  <option value="">Select Condition</option>
                  <option value="new">New</option>
                  <option value="like-new">Like New</option>
                  <option value="good">Good</option>
                  <option value="fair">Fair</option>
                </select>
              </div>
              <div className="md:col-span-2">
                <label className="block text-sm font-medium text-gray-700 mb-2">Description</label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  rows={4}
                  className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#FFD700]"
                  placeholder="Please provide details about your item..."
                />
              </div>
              <div className="md:col-span-2">
                <label className="block text-sm font-medium text-gray-700 mb-2">Asking Price (USD)</label>
                <input
                  type="number"
                  name="askingPrice"
                  value={formData.askingPrice}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#FFD700]"
                  placeholder="Enter your asking price"
                />
              </div>

              {/* Contact Information */}
              <div className="md:col-span-2 mt-8">
                <h3 className="font-playfair text-2xl mb-4">Contact Information</h3>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Full Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#FFD700]"
                  placeholder="Your full name"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#FFD700]"
                  placeholder="Your email address"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Phone Number</label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#FFD700]"
                  placeholder="Your phone number"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Preferred Pickup Date</label>
                <input
                  type="date"
                  name="preferredDate"
                  value={formData.preferredDate}
                  onChange={handleChange}
                  className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#FFD700]"
                />
              </div>
              <div className="md:col-span-2">
                <button
                  type="submit"
                  className="w-full bg-[#FFD700] text-black py-4 rounded-md hover:bg-[#E5C100] transition-colors text-lg font-semibold"
                >
                  Submit Item for Review
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>

      <Footer />
    </div>
  );
};

const BenefitCard = ({ Icon, title, description }) => {
  return (
    <div className="text-center p-8 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow">
      <div className="inline-block p-4 bg-[#FFD700]/10 rounded-full mb-6">
        <Icon className="w-8 h-8 text-[#FFD700]" />
      </div>
      <h3 className="font-playfair text-2xl mb-4">{title}</h3>
      <p className="font-lato text-gray-600 text-lg">{description}</p>
    </div>
  );
};

const ProcessStep = ({ number, title, description }) => {
  return (
    <div className="text-center p-8">
      <div className="relative inline-block">
        <div className="w-16 h-16 bg-[#FFD700] rounded-full flex items-center justify-center mb-6">
          <span className="text-2xl font-bold text-black">{number}</span>
        </div>
      </div>
      <h3 className="font-playfair text-2xl mb-4">{title}</h3>
      <p className="font-lato text-gray-600 text-lg">{description}</p>
    </div>
  );
};

const VerificationStep = ({ Icon, number, title, description }) => {
  return (
    <div className="relative">
      {/* Connector line */}
      {number < 4 && (
        <div className="hidden md:block absolute top-12 left-1/2 w-full h-0.5 bg-[#FFD700]/30"></div>
      )}
      
      <div className="relative text-center p-8">
        <div className="relative inline-block">
          <div className="w-24 h-24 bg-[#FFD700]/10 rounded-full flex items-center justify-center mb-6">
            <Icon className="w-12 h-12 text-[#FFD700]" />
          </div>
          <div className="absolute -top-2 -right-2 w-8 h-8 bg-[#FFD700] rounded-full flex items-center justify-center">
            <span className="text-lg font-bold text-black">{number}</span>
          </div>
        </div>
        <h3 className="font-playfair text-xl mb-3">{title}</h3>
        <p className="font-lato text-gray-600">{description}</p>
      </div>
    </div>
  );
};

export default SellWithUs;