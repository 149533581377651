import React, { useState } from 'react';
import { supabase } from '../lib/supabaseClient';
import { FileText, CreditCard, Truck, Shield } from 'lucide-react';

interface TransactionFormProps {
  isOpen: boolean;
  onClose: () => void;
}

const TransactionForm: React.FC<TransactionFormProps> = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    // Seller Information
    seller_name: '',
    seller_email: '',
    seller_phone: '',
    pickup_address: '',
    
    // Item Details
    item_type: '',
    brand: '',
    model: '',
    
    // Shipping
    shipping_address: '',
    
    // Additional Details
    order_details: '',
    special_terms: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    try {
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) throw new Error('Please sign in to continue');

      const { error: submitError } = await supabase
        .from('transactions')
        .insert([{
          buyer_id: user.id,
          ...formData
        }]);

      if (submitError) throw submitError;

      setSuccess(true);
      setTimeout(() => {
        onClose();
        setSuccess(false);
        setFormData({
          seller_name: '',
          seller_email: '',
          seller_phone: '',
          pickup_address: '',
          item_type: '',
          brand: '',
          model: '',
          shipping_address: '',
          order_details: '',
          special_terms: ''
        });
        setStep(1);
      }, 3000);
    } catch (err) {
      setError('An error occurred while submitting your request. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity bg-black bg-opacity-75" onClick={onClose} />

        <div className="inline-block w-full max-w-2xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-lg shadow-xl sm:align-middle">
          {success ? (
            <div className="p-8 text-center">
              <div className="w-16 h-16 mx-auto mb-4 bg-green-100 rounded-full flex items-center justify-center">
                <Shield className="w-8 h-8 text-green-500" />
              </div>
              <h3 className="text-2xl font-playfair mb-2">Transaction Initiated!</h3>
              <p className="text-gray-600">
                Your transaction request has been submitted successfully. We'll contact the seller and guide you through the next steps.
              </p>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              {/* Progress Steps */}
              <div className="px-8 pt-8">
                <div className="flex justify-between mb-8">
                  <Step
                    number={1}
                    title="Seller Info"
                    icon={FileText}
                    isActive={step === 1}
                    isCompleted={step > 1}
                  />
                  <Step
                    number={2}
                    title="Item Details"
                    icon={Shield}
                    isActive={step === 2}
                    isCompleted={step > 2}
                  />
                  <Step
                    number={3}
                    title="Shipping"
                    icon={Truck}
                    isActive={step === 3}
                    isCompleted={step > 3}
                  />
                  <Step
                    number={4}
                    title="Payment"
                    icon={CreditCard}
                    isActive={step === 4}
                    isCompleted={step > 4}
                  />
                </div>
              </div>

              <div className="px-8 pb-8">
                {step === 1 && (
                  <div className="space-y-6">
                    <h3 className="text-2xl font-playfair mb-6">Seller Information</h3>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Seller Name
                      </label>
                      <input
                        type="text"
                        name="seller_name"
                        required
                        value={formData.seller_name}
                        onChange={handleChange}
                        className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-gold-300 focus:border-transparent"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Seller Email
                      </label>
                      <input
                        type="email"
                        name="seller_email"
                        required
                        value={formData.seller_email}
                        onChange={handleChange}
                        className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-gold-300 focus:border-transparent"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Seller Phone
                      </label>
                      <input
                        type="tel"
                        name="seller_phone"
                        required
                        value={formData.seller_phone}
                        onChange={handleChange}
                        className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-gold-300 focus:border-transparent"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Pickup Address
                      </label>
                      <textarea
                        name="pickup_address"
                        required
                        value={formData.pickup_address}
                        onChange={handleChange}
                        rows={3}
                        className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-gold-300 focus:border-transparent"
                      />
                    </div>
                  </div>
                )}

                {step === 2 && (
                  <div className="space-y-6">
                    <h3 className="text-2xl font-playfair mb-6">Item Details</h3>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Item Type
                      </label>
                      <select
                        name="item_type"
                        required
                        value={formData.item_type}
                        onChange={handleChange}
                        className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-gold-300 focus:border-transparent"
                      >
                        <option value="">Select Type</option>
                        <option value="watch">Watch</option>
                        <option value="handbag">Handbag</option>
                        <option value="jewelry">Jewelry</option>
                        <option value="accessory">Accessory</option>
                      </select>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Brand
                      </label>
                      <input
                        type="text"
                        name="brand"
                        required
                        value={formData.brand}
                        onChange={handleChange}
                        className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-gold-300 focus:border-transparent"
                        placeholder="e.g., Rolex, Louis Vuitton"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Model
                      </label>
                      <input
                        type="text"
                        name="model"
                        required
                        value={formData.model}
                        onChange={handleChange}
                        className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-gold-300 focus:border-transparent"
                        placeholder="e.g., Submariner, Neverfull"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Additional Details
                      </label>
                      <textarea
                        name="order_details"
                        value={formData.order_details}
                        onChange={handleChange}
                        rows={3}
                        className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-gold-300 focus:border-transparent"
                        placeholder="Any specific details about the item..."
                      />
                    </div>
                  </div>
                )}

                {step === 3 && (
                  <div className="space-y-6">
                    <h3 className="text-2xl font-playfair mb-6">Shipping Information</h3>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Delivery Address
                      </label>
                      <textarea
                        name="shipping_address"
                        required
                        value={formData.shipping_address}
                        onChange={handleChange}
                        rows={3}
                        className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-gold-300 focus:border-transparent"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Special Terms
                      </label>
                      <textarea
                        name="special_terms"
                        value={formData.special_terms}
                        onChange={handleChange}
                        rows={3}
                        className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-gold-300 focus:border-transparent"
                        placeholder="Any special handling instructions or terms..."
                      />
                    </div>
                  </div>
                )}

                {step === 4 && (
                  <div className="space-y-6">
                    <h3 className="text-2xl font-playfair mb-6">Payment Details</h3>
                    <div className="bg-gray-50 p-6 rounded-lg">
                      <h4 className="font-semibold mb-4">Service Fee Breakdown</h4>
                      <div className="space-y-2">
                        <div className="flex justify-between">
                          <span>Authentication Service</span>
                          <span>₹2,999</span>
                        </div>
                        <div className="flex justify-between text-sm text-gray-600">
                          <span>GST (18%)</span>
                          <span>₹539.82</span>
                        </div>
                        <div className="flex justify-between font-bold pt-2 border-t">
                          <span>Total Amount</span>
                          <span>₹3,538.82</span>
                        </div>
                      </div>
                    </div>
                    <div className="bg-yellow-50 p-4 rounded-lg">
                      <p className="text-sm text-yellow-800">
                        By proceeding, you agree to pay the service fee. The payment for the item will be handled separately after authentication.
                      </p>
                    </div>
                  </div>
                )}

                {error && (
                  <div className="mt-4 p-3 bg-red-50 text-red-700 rounded-md">
                    {error}
                  </div>
                )}

                <div className="mt-8 flex justify-between">
                  {step > 1 && (
                    <button
                      type="button"
                      onClick={prevStep}
                      className="px-6 py-2 border border-gray-300 rounded-md hover:bg-gray-50 transition-colors"
                    >
                      Back
                    </button>
                  )}
                  {step < 4 ? (
                    <button
                      type="button"
                      onClick={nextStep}
                      className="ml-auto px-6 py-2 bg-[#FFD700] text-black rounded-md hover:bg-[#E5C100] transition-colors"
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="ml-auto px-6 py-2 bg-[#FFD700] text-black rounded-md hover:bg-[#E5C100] transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {isSubmitting ? 'Processing...' : 'Submit Request'}
                    </button>
                  )}
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

const Step = ({ number, title, icon: Icon, isActive, isCompleted }) => {
  return (
    <div className="flex flex-col items-center">
      <div
        className={`w-10 h-10 rounded-full flex items-center justify-center mb-2 ${
          isActive
            ? 'bg-[#FFD700] text-black'
            : isCompleted
            ? 'bg-green-500 text-white'
            : 'bg-gray-200 text-gray-500'
        }`}
      >
        {isCompleted ? (
          <Icon className="w-5 h-5" />
        ) : (
          <span className="font-semibold">{number}</span>
        )}
      </div>
      <span className={`text-sm ${isActive ? 'text-[#FFD700] font-semibold' : 'text-gray-500'}`}>
        {title}
      </span>
    </div>
  );
};

export default TransactionForm;