import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, Star } from 'lucide-react';

const testimonials = [
  {
    id: 1,
    name: "Sarah Johnson",
    role: "Luxury Collector",
    image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=80",
    text: "WebInfoIT has transformed how I shop for luxury items. Their authentication process gives me complete peace of mind.",
    rating: 5
  },
  {
    id: 2,
    name: "Michael Chen",
    role: "Fashion Enthusiast",
    image: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=80",
    text: "The quality of items and customer service is unmatched. I've found incredible pieces at amazing values.",
    rating: 5
  },
  {
    id: 3,
    name: "Emma Thompson",
    role: "Professional Seller",
    image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=80",
    text: "As a seller, WebInfoIT makes it easy to reach genuine buyers. Their platform is simply exceptional.",
    rating: 5
  }
];

const TestimonialCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const next = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prev = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="relative max-w-4xl mx-auto">
      <div className="overflow-hidden rounded-xl bg-white shadow-lg">
        <div className="px-8 py-12">
          <div className="flex flex-col items-center text-center">
            <img
              src={testimonials[currentIndex].image}
              alt={testimonials[currentIndex].name}
              className="w-24 h-24 rounded-full object-cover mb-6 ring-4 ring-[#FFD700]/20"
            />
            <div className="flex mb-4">
              {[...Array(testimonials[currentIndex].rating)].map((_, i) => (
                <Star key={i} className="w-5 h-5 text-[#FFD700] fill-current" />
              ))}
            </div>
            <p className="font-lato text-gray-600 text-xl mb-8 italic max-w-2xl">
              "{testimonials[currentIndex].text}"
            </p>
            <h4 className="font-playfair text-2xl font-semibold mb-2">
              {testimonials[currentIndex].name}
            </h4>
            <p className="font-lato text-gray-500 text-lg">
              {testimonials[currentIndex].role}
            </p>
          </div>
        </div>
      </div>

      {/* Navigation Buttons */}
      <button
        onClick={prev}
        className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4 bg-white p-3 rounded-full shadow-lg hover:bg-gray-50 transition-colors"
      >
        <ChevronLeft className="w-6 h-6" />
      </button>
      <button
        onClick={next}
        className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 bg-white p-3 rounded-full shadow-lg hover:bg-gray-50 transition-colors"
      >
        <ChevronRight className="w-6 h-6" />
      </button>
    </div>
  );
};

export default TestimonialCarousel;