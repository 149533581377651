import React, { useState } from 'react';
import { Shield, Heart, Target, Search, FileCheck, CheckCircle } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import AuthenticationServiceModal from '../components/AuthenticationServiceModal';

const teamMembers = [
  {
    name: "Akashay Kumar",
    role: "Director",
    image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
    bio: "With extensive experience in luxury retail and technology, Akashay leads WebInfoIT's strategic vision and growth initiatives."
  },
  {
    name: "Deepika Kumari",
    role: "Director",
    image: "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
    bio: "Deepika brings her expertise in luxury authentication and customer experience to drive WebInfoIT's service excellence."
  }
];

const AboutUs = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      
      {/* Hero Section */}
      <section className="relative h-[60vh] flex items-center justify-center">
        <div 
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: 'url("https://images.unsplash.com/photo-1441986300917-64674bd600d8?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="absolute inset-0 bg-black/50"></div>
        </div>
        <div className="relative z-10 text-center text-white px-4 max-w-5xl mx-auto">
          <h1 className="font-playfair text-5xl md:text-6xl mb-6">Who We Are</h1>
          <p className="font-lato text-xl md:text-2xl max-w-3xl mx-auto">
            Building trust in the pre-owned luxury goods market
          </p>
        </div>
      </section>

      {/* Authentication Service Section */}
      <section className="py-20 px-4 bg-luxury-900">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div className="space-y-6">
              <h2 className="font-playfair text-4xl md:text-5xl text-white">
                Expert Authentication Services
              </h2>
              <p className="text-xl text-gray-300">
                We specialize in comprehensive inspection, verification, and authenticity validation services for luxury goods.
              </p>
              <div className="space-y-4">
                <div className="flex items-start space-x-3">
                  <Search className="w-6 h-6 text-gold-300 mt-1 flex-shrink-0" />
                  <p className="text-gray-300">Detailed physical inspection by certified experts</p>
                </div>
                <div className="flex items-start space-x-3">
                  <FileCheck className="w-6 h-6 text-gold-300 mt-1 flex-shrink-0" />
                  <p className="text-gray-300">Comprehensive documentation verification</p>
                </div>
                <div className="flex items-start space-x-3">
                  <CheckCircle className="w-6 h-6 text-gold-300 mt-1 flex-shrink-0" />
                  <p className="text-gray-300">Authentication certificate issuance</p>
                </div>
              </div>
              <div className="bg-luxury-800 p-6 rounded-lg border border-gold-300/20">
                <div className="flex justify-between items-center mb-3">
                  <span className="text-white font-playfair text-xl">Service Fee</span>
                  <span className="text-gold-300 font-bold text-2xl">₹2,999</span>
                </div>
                <p className="text-gray-400 text-sm">+ 18% GST applicable</p>
                <div className="mt-4 pt-4 border-t border-luxury-700">
                  <button
                    onClick={() => setIsModalOpen(true)}
                    className="btn-primary w-full text-center inline-block"
                  >
                    Book Authentication Service
                  </button>
                </div>
              </div>
            </div>
            <div className="relative">
              <div className="aspect-square rounded-lg overflow-hidden">
                <img
                  src="https://images.unsplash.com/photo-1617870952348-7524edfb61b7?auto=format&fit=crop&w=800&q=80"
                  alt="Authentication Process"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="absolute -bottom-6 -right-6 bg-white p-6 rounded-lg shadow-luxury max-w-xs">
                <div className="flex items-center space-x-3 mb-3">
                  <Shield className="w-8 h-8 text-gold-300" />
                  <h3 className="font-playfair text-lg">100% Authentic</h3>
                </div>
                <p className="text-gray-600">
                  Every item undergoes our rigorous authentication process
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Mission, Vision, Values Section */}
      <section className="py-20 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <ValueCard
              Icon={Target}
              title="Our Mission"
              description="To revolutionize the luxury resale industry with transparency and reliability."
            />
            <ValueCard
              Icon={Shield}
              title="Our Vision"
              description="To become the most trusted platform for pre-owned luxury goods globally."
            />
            <ValueCard
              Icon={Heart}
              title="Our Values"
              description="Trust, authenticity, and customer satisfaction guide everything we do."
            />
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section className="py-20 px-4 bg-gray-50">
        <div className="max-w-7xl mx-auto">
          <h2 className="font-playfair text-4xl text-center mb-16">Leadership Team</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 max-w-4xl mx-auto">
            {teamMembers.map((member) => (
              <TeamMemberCard key={member.name} {...member} />
            ))}
          </div>
        </div>
      </section>

      <Footer />

      <AuthenticationServiceModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

const ValueCard = ({ Icon, title, description }) => {
  return (
    <div className="text-center p-8">
      <div className="inline-block p-4 bg-[#FFD700]/10 rounded-full mb-6">
        <Icon className="w-8 h-8 text-[#FFD700]" />
      </div>
      <h3 className="font-playfair text-2xl mb-4">{title}</h3>
      <p className="font-lato text-gray-600 text-lg">{description}</p>
    </div>
  );
};

const TeamMemberCard = ({ name, role, image, bio }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow">
      <img
        src={image}
        alt={name}
        className="w-full h-64 object-cover"
      />
      <div className="p-6">
        <h3 className="font-playfair text-2xl mb-2">{name}</h3>
        <p className="text-[#FFD700] font-semibold mb-4">{role}</p>
        <p className="font-lato text-gray-600">{bio}</p>
      </div>
    </div>
  );
};

export default AboutUs;