import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { DollarSign, Clock, Shield, CreditCard } from 'lucide-react';

const RefundPolicy = () => {
  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      
      {/* Hero Section */}
      <section className="relative h-[40vh] flex items-center justify-center">
        <div 
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: 'url("https://images.unsplash.com/photo-1554224155-6726b3ff858f?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="absolute inset-0 bg-black/50"></div>
        </div>
        <div className="relative z-10 text-center text-white px-4">
          <h1 className="font-playfair text-5xl md:text-6xl mb-4">Refund Policy</h1>
          <p className="font-lato text-xl max-w-2xl mx-auto">
            Learn about our refund process and timelines
          </p>
        </div>
      </section>

      {/* Content Section */}
      <section className="py-16 px-4">
        <div className="max-w-4xl mx-auto">
          <div className="prose prose-lg">
            <div className="mb-8">
              <p className="text-gray-600">
                At WebInfoIT, we strive to provide a seamless shopping experience. If you are not completely satisfied with your purchase, you may return eligible items by following our return policy guidelines.
              </p>
            </div>

            <div className="mb-12">
              <div className="flex items-center space-x-3 mb-6">
                <Clock className="w-8 h-8 text-[#FFD700]" />
                <h2 className="font-playfair text-3xl m-0">Return Window</h2>
              </div>
              <p>We offer a structured return policy to ensure customer satisfaction:</p>
              <ul>
                <li><strong>3-Day Inspection Period:</strong> After receiving your order, you have three days to inspect the item and report any issues.</li>
                <li><strong>7-Day Return Window:</strong> If your item is authenticated and meets return eligibility criteria, you may return it within seven days of delivery.</li>
                <li><strong>Immediate Returns for Non-Authentic Items:</strong> If an item is found to be non-authentic, you may return it immediately without any return window constraints.</li>
                <li><strong>Extended Return Window for Hidden Defects:</strong> If you discover a hidden defect after the initial inspection period, you may still be eligible for a return under certain conditions.</li>
              </ul>
            </div>

            <div className="mb-12">
              <div className="flex items-center space-x-3 mb-6">
                <DollarSign className="w-8 h-8 text-[#FFD700]" />
                <h2 className="font-playfair text-3xl m-0">Refund Eligibility</h2>
              </div>
              <p>We offer full refunds for:</p>
              <ul>
                <li><strong>Non-authentic items:</strong> If an item is found to be non-authentic, we will issue a <strong>100% refund</strong>, including shipping costs.</li>
                <li><strong>Items not matching description:</strong> If an item does not match the product description, customers are eligible for a full refund.</li>
                <li><strong>Damaged items received:</strong> If an item arrives damaged, we will process a full refund upon verification.</li>
                <li><strong>Cancelled orders before shipping:</strong> If an order is cancelled before it has been shipped, we will issue a full refund.</li>
              </ul>
            </div>

            <div className="mb-12">
              <div className="flex items-center space-x-3 mb-6">
                <Clock className="w-8 h-8 text-[#FFD700]" />
                <h2 className="font-playfair text-3xl m-0">Refund Timeline</h2>
              </div>
              <p>Our refund processing timeline:</p>
              <ul>
                <li><strong>Immediate refund:</strong> If an item fails authentication</li>
                <li><strong>3-5 business days:</strong> For returned items that pass inspection</li>
                <li><strong>1-2 business days:</strong> For cancelled orders before shipping</li>
                <li><strong>Bank processing time:</strong> Additional 2-7 business days for the refund to reflect in your account</li>
              </ul>
            </div>

            <div className="mb-12">
              <div className="flex items-center space-x-3 mb-6">
                <CreditCard className="w-8 h-8 text-[#FFD700]" />
                <h2 className="font-playfair text-3xl m-0">Refund Methods</h2>
              </div>
              <p>Refunds are processed through:</p>
              <ul>
                <li><strong>Original payment method:</strong> Refunds are issued back to the payment method used at checkout</li>
                <li><strong>Bank transfer:</strong> In special cases, refunds may be processed via direct bank transfer</li>
                <li><strong>Store credit:</strong> Customers may opt for store credit instead of a direct refund</li>
                <li><strong>Digital wallets:</strong> If applicable, refunds can be credited to supported digital wallets</li>
              </ul>
            </div>

            <div className="mb-12">
              <div className="flex items-center space-x-3 mb-6">
                <Shield className="w-8 h-8 text-[#FFD700]" />
                <h2 className="font-playfair text-3xl m-0">Buyer Protection</h2>
              </div>
              <p>Our buyer protection includes:</p>
              <ul>
                <li><strong>Money-back guarantee:</strong> Protection against fraudulent or incorrect transactions</li>
                <li><strong>Secure payment processing:</strong> All transactions are securely processed with encryption</li>
                <li><strong>Documented refund process:</strong> Detailed records of all refund requests for transparency</li>
                <li><strong>Customer support assistance:</strong> Dedicated support team for refund-related queries</li>
              </ul>
            </div>

            <div className="mb-12">
              <h2 className="font-playfair text-3xl mb-6">Return Process</h2>
              <p>To initiate a return and refund:</p>
              <ol className="space-y-4">
                <li><strong>Contact Customer Support:</strong> Email returns@webinfoit.com to request a return authorization</li>
                <li><strong>Receive Authorization:</strong> Get your return authorization number and instructions</li>
                <li><strong>Schedule Pickup:</strong> We'll arrange a secure pickup from your location</li>
                <li><strong>Item Inspection:</strong> Our team will verify the item's condition (up to 5 business days)</li>
                <li><strong>Refund Processing:</strong> Once approved, refund will be issued within 7-10 business days</li>
              </ol>
            </div>

            <div className="mb-12 bg-gray-50 p-6 rounded-lg">
              <h3 className="font-playfair text-2xl mb-4">Important Notes</h3>
              <ul className="space-y-2">
                <li>Shipping charges may apply unless return is due to product defect or error</li>
                <li>Refunds are processed only after item inspection and approval</li>
                <li>Non-compliant returns will be shipped back to the customer</li>
              </ul>
            </div>

            <div className="bg-gray-50 p-8 rounded-lg">
              <h3 className="font-playfair text-2xl mb-4">Contact Information</h3>
              <p>For refund-related queries, please contact us at:</p>
              <p className="font-semibold mb-2">refunds@webinfoit.com</p>
              <p className="text-gray-600">
                Customer Service Hours:<br />
                Monday to Friday, 9 AM – 6 PM (Local Time)
              </p>
            </div>

            <div className="mt-8">
              <p className="text-gray-600 italic">
                We value your trust and aim to make your shopping experience as smooth as possible. Thank you for choosing WebInfoIT!
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default RefundPolicy;