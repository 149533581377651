import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Scale, FileCheck, AlertTriangle, Mail, Phone, MapPin, Clock } from 'lucide-react';

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-white">
      <Navbar />
      
      {/* Hero Section */}
      <section className="relative h-[40vh] flex items-center justify-center">
        <div 
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: 'url("https://images.unsplash.com/photo-1589829545856-d10d557cf95f?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="absolute inset-0 bg-black/50"></div>
        </div>
        <div className="relative z-10 text-center text-white px-4">
          <h1 className="font-playfair text-5xl md:text-6xl mb-4">Terms of Service</h1>
          <p className="font-lato text-xl max-w-2xl mx-auto">
            Please read these terms carefully before using our services
          </p>
        </div>
      </section>

      {/* Content Section */}
      <section className="py-16 px-4">
        <div className="max-w-4xl mx-auto">
          <div className="prose prose-lg">
            <div className="mb-12">
              <h2 className="font-playfair text-3xl mb-4">Agreement to Terms</h2>
              <p>
                By accessing or using the services provided by WebInfoIT PRIVATE LIMITED, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with any part of these terms, you must not use our services.
              </p>
            </div>

            <div className="mb-12">
              <h2 className="font-playfair text-3xl mb-4">User Responsibilities</h2>
              <p>By using our services, you agree to:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Provide accurate and complete information when required.</li>
                <li>Maintain the confidentiality of your account credentials.</li>
                <li>Not engage in fraudulent or deceptive practices.</li>
                <li>Respect the intellectual property rights of WebInfoIT PRIVATE LIMITED and third parties.</li>
                <li>Abide by all applicable laws and regulations.</li>
              </ul>
            </div>

            <div className="mb-12">
              <h2 className="font-playfair text-3xl mb-4">Authentication and Verification</h2>
              <p>For platforms selling physical products such as tempered glass and mobile accessories, we implement the following authentication measures:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Physical inspection by quality control experts.</li>
                <li>Verification of product documentation and certifications.</li>
                <li>Material and durability assessments.</li>
                <li>Serial number validation where applicable.</li>
              </ul>
            </div>

            <div className="mb-12">
              <h2 className="font-playfair text-3xl mb-4">Payment and Refund Policy</h2>
              <p>Payments are securely processed via authorized payment gateways. Our policies include:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Secure escrow services (if applicable).</li>
                <li>Inspection periods for certain products.</li>
                <li>Full or partial refunds in cases of non-authentic or damaged goods.</li>
                <li>No Cash on Delivery (COD) options for specific platforms (e.g., Glam24 only accepts prepaid orders).</li>
              </ul>
            </div>

            <div className="mb-12">
              <h2 className="font-playfair text-3xl mb-4">Limitation of Liability</h2>
              <p>WebInfoIT PRIVATE LIMITED shall not be held liable for:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li>Any indirect, incidental, or consequential damages arising from the use of our services.</li>
                <li>Loss of profits, data, or business opportunities.</li>
                <li>Service interruptions due to technical issues, maintenance, or force majeure events.</li>
                <li>Actions or omissions of third-party service providers, including logistics and payment processors.</li>
              </ul>
            </div>

            <div className="mb-12">
              <h2 className="font-playfair text-3xl mb-4">Intellectual Property</h2>
              <p>
                All content on our platforms, including text, images, logos, and trademarks, is the exclusive property of WebInfoIT PRIVATE LIMITED and protected under applicable copyright and trademark laws. Unauthorized use or reproduction is strictly prohibited.
              </p>
            </div>

            <div className="mb-12">
              <h2 className="font-playfair text-3xl mb-4">Amendments to Terms</h2>
              <p>
                We reserve the right to modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting on our platforms. Continued use of our services constitutes acceptance of the revised terms.
              </p>
            </div>

            <div className="mb-12">
              <h2 className="font-playfair text-3xl mb-4">Governing Law</h2>
              <p>
                These Terms and Conditions shall be governed by and interpreted under the laws of India. Any disputes shall be resolved exclusively in the courts of Gurgaon, Haryana.
              </p>
            </div>

            <div className="mb-12">
              <h2 className="font-playfair text-3xl mb-4">Contact Information</h2>
              <p>For any questions or concerns regarding these Terms and Conditions, please contact us:</p>
              
              <div className="mt-6 space-y-6">
                <div className="flex items-start space-x-4">
                  <Mail className="w-6 h-6 text-[#FFD700] flex-shrink-0" />
                  <div>
                    <h3 className="font-medium">Email Us</h3>
                    <p>info@webinfoit.com</p>
                    <p className="text-sm text-gray-600">For general inquiries and support</p>
                  </div>
                </div>

                <div className="flex items-start space-x-4">
                  <Phone className="w-6 h-6 text-[#FFD700] flex-shrink-0" />
                  <div>
                    <h3 className="font-medium">Call Us</h3>
                    <p>+91-9818935567</p>
                    <p className="text-sm text-gray-600">Monday to Friday, 9am - 6pm</p>
                  </div>
                </div>

                <div className="flex items-start space-x-4">
                  <MapPin className="w-6 h-6 text-[#FFD700] flex-shrink-0" />
                  <div>
                    <h3 className="font-medium">Visit Us</h3>
                    <p>WebInfoIT PRIVATE LIMITED</p>
                    <p className="text-sm text-gray-600">
                      OFFICE NO-1133 11TH FLOOR TOWER B2 SPAZE I TECH PARK SECTOR 49 GURGAON
                    </p>
                  </div>
                </div>

                <div className="flex items-start space-x-4">
                  <Clock className="w-6 h-6 text-[#FFD700] flex-shrink-0" />
                  <div>
                    <h3 className="font-medium">Business Hours</h3>
                    <p>Monday - Friday: 9:00 AM - 6:00 PM</p>
                    <p>Saturday: 10:00 AM - 4:00 PM</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-8 p-6 bg-gray-50 rounded-lg">
              <p className="text-gray-600 italic">
                By using our services, you acknowledge that you have read, understood, and agree to be bound by these terms.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default TermsOfService;