import React, { useState, useEffect } from 'react';
import { Menu, X, ShoppingBag, User } from 'lucide-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../lib/supabaseClient';
import AuthModal from './AuthModal';
import CartDropdown from './CartDropdown';
import { useCart } from '../lib/cartContext';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { state: cartState } = useCart();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    checkUser();
    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      setUser(session?.user ?? null);
      setLoading(false);
    });

    return () => {
      if (authListener && authListener.unsubscribe) {
        authListener.unsubscribe();
      }
    };
  }, []);

  const checkUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      setUser(user);
    } catch (error) {
      console.error('Error checking user:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    try {
      await supabase.auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleExploreProducts = () => {
    navigate('/shop');
  };

  const handleStartSelling = () => {
    navigate('/sell');
  };

  const navigationItems = [
    { name: 'Home', path: '/' },
    { name: 'About Us', path: '/about' },
    { name: 'How It Works', path: '/how-it-works' },
    { name: 'Blog', path: '/blog' },
    { name: 'FAQ', path: '/faq' },
    { name: 'Contact Us', path: '/contact' }
  ];

  const cartItemCount = cartState.items.reduce((total, item) => total + item.quantity, 0);

  return (
    <>
      <nav className={`fixed w-full z-50 transition-all duration-300 ${
        isScrolled ? 'bg-white/95 backdrop-blur-sm shadow-luxury' : 'bg-white/80 backdrop-blur-sm'
      }`}>
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between items-center h-20">
            {/* Logo */}
            <Link to="/" className="flex-shrink-0 group">
              <div className="flex flex-col">
                <h1 className="font-playfair text-2xl font-bold group-hover:text-gold-300 transition-colors">
                  WebInfoIT
                </h1>
                <p className="text-xs text-gray-600">
                  Revolutionizing Trust in Pre-Owned Luxury Goods
                </p>
              </div>
            </Link>

            {/* Desktop Navigation */}
            <div className="hidden lg:flex items-center space-x-8">
              {navigationItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.path}
                  className={`font-lato text-sm tracking-wide hover:text-gold-300 transition-colors relative py-2
                    ${location.pathname === item.path ? 'text-gold-300' : 'text-luxury-800'}
                    after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5
                    after:bg-gold-300 after:scale-x-0 after:origin-right after:transition-transform
                    hover:after:scale-x-100 hover:after:origin-left`}
                >
                  {item.name}
                </Link>
              ))}
            </div>

            {/* CTA Buttons and Auth */}
            <div className="hidden lg:flex items-center space-x-4">
              <button 
                onClick={handleExploreProducts}
                className="btn-primary"
              >
                Explore Products
              </button>

              {/* Cart Icon */}
              <div className="relative">
                <button
                  onClick={() => setIsCartOpen(!isCartOpen)}
                  className="p-2 hover:text-gold-300 transition-colors relative"
                >
                  <ShoppingBag className="w-6 h-6" />
                  {cartItemCount > 0 && (
                    <span className="absolute -top-1 -right-1 bg-gold-300 text-black w-5 h-5 rounded-full text-xs flex items-center justify-center font-bold">
                      {cartItemCount}
                    </span>
                  )}
                </button>
                {isCartOpen && <CartDropdown isOpen={isCartOpen} onClose={() => setIsCartOpen(false)} />}
              </div>

              {loading ? (
                <div className="w-8 h-8 animate-pulse bg-gray-200 rounded-full"></div>
              ) : user ? (
                <div className="relative group">
                  <button className="flex items-center space-x-2 text-luxury-800 hover:text-gold-300 transition-colors">
                    <User className="w-5 h-5" />
                    <span className="text-sm font-medium">Account</span>
                  </button>
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200">
                    <div className="px-4 py-2 border-b border-gray-100">
                      <p className="text-sm text-gray-600">Signed in as</p>
                      <p className="text-sm font-medium truncate">{user.email}</p>
                    </div>
                    <Link
                      to="/profile"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Profile
                    </Link>
                    <Link
                      to="/profile"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Orders
                    </Link>
                    <button
                      onClick={handleSignOut}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Sign Out
                    </button>
                  </div>
                </div>
              ) : (
                <button
                  onClick={() => setIsAuthModalOpen(true)}
                  className="btn-secondary"
                >
                  Sign In
                </button>
              )}
            </div>

            {/* Mobile menu button */}
            <div className="lg:hidden flex items-center space-x-4">
              {/* Cart Icon */}
              <button
                onClick={() => setIsCartOpen(!isCartOpen)}
                className="p-2 hover:text-gold-300 transition-colors relative"
              >
                <ShoppingBag className="w-6 h-6" />
                {cartItemCount > 0 && (
                  <span className="absolute -top-1 -right-1 bg-gold-300 text-black w-5 h-5 rounded-full text-xs flex items-center justify-center font-bold">
                    {cartItemCount}
                  </span>
                )}
              </button>

              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="p-2 rounded-md text-luxury-800 hover:text-gold-300 hover:bg-gold-50 transition-colors"
              >
                {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        <div
          className={`lg:hidden absolute w-full bg-white/95 backdrop-blur-sm shadow-luxury transition-all duration-300 ease-in-out ${
            isMenuOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4 pointer-events-none'
          }`}
        >
          <div className="px-4 py-6 space-y-4">
            {navigationItems.map((item) => (
              <Link
                key={item.name}
                to={item.path}
                className={`block px-4 py-2 text-base font-medium rounded-md transition-colors
                  ${location.pathname === item.path ? 'text-gold-300 bg-gold-50' : 'text-luxury-800 hover:text-gold-300 hover:bg-gold-50'}`}
                onClick={() => setIsMenuOpen(false)}
              >
                {item.name}
              </Link>
            ))}
            <div className="pt-4 space-y-3 px-4">
              {loading ? (
                <div className="w-full h-10 animate-pulse bg-gray-200 rounded-md"></div>
              ) : user ? (
                <>
                  <Link
                    to="/profile"
                    className="block px-4 py-2 text-base font-medium text-luxury-800 hover:text-gold-300 hover:bg-gold-50 rounded-md"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Profile
                  </Link>
                  <Link
                    to="/profile"
                    className="block px-4 py-2 text-base font-medium text-luxury-800 hover:text-gold-300 hover:bg-gold-50 rounded-md"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Orders
                  </Link>
                  <button
                    onClick={() => {
                      handleSignOut();
                      setIsMenuOpen(false);
                    }}
                    className="w-full text-left px-4 py-2 text-base font-medium text-luxury-800 hover:text-gold-300 hover:bg-gold-50 rounded-md"
                  >
                    Sign Out
                  </button>
                </>
              ) : (
                <button
                  onClick={() => {
                    setIsAuthModalOpen(true);
                    setIsMenuOpen(false);
                  }}
                  className="w-full btn-primary"
                >
                  Sign In
                </button>
              )}
              <button 
                onClick={() => {
                  handleExploreProducts();
                  setIsMenuOpen(false);
                }}
                className="w-full btn-primary"
              >
                Explore Products
              </button>
              <button 
                onClick={() => {
                  handleStartSelling();
                  setIsMenuOpen(false);
                }}
                className="w-full btn-secondary"
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </nav>

      <AuthModal
        isOpen={isAuthModalOpen}
        onClose={() => setIsAuthModalOpen(false)}
      />

      {isCartOpen && <CartDropdown isOpen={isCartOpen} onClose={() => setIsCartOpen(false)} />}
    </>
  );
};

export default Navbar;